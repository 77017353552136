<template>
	<div id="home" class="index">
		<ul id="tab" style="margin-left: 0px; padding-left: 0px;">
			<li><router-link to="/"><img src="../assets/common/ico-30-tab-event.svg">EVENT</router-link></li>
			<li class="on"><router-link to="/index"><img src="../assets/common/ico-30-tab-hope-active.svg">희망거래</router-link></li>
		</ul>
		<!-- <div id="banner" class="swiper swiper_01"> -->
			<swiper id="banner" class="swiper swiper_01" :options="swiperOptions" >
				<!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->
				<swiper-slide v-if="!isMo" class="banner_01" v-for="(item,index) in goods_list" :key="index"  :style="'background-image:url(' + imageSrc + item.goods_img + ')'" >
					<div>
						<!-- <h2>원하는 가격으로 <br /><em>구매해 보세요.</em></h2>
						<p>내가 원하는 가격으로 상품을 구매할 수 있어요. <br />원하는 가격을 한번 제시해 보세요.</p> -->
						<ul style="margin-top: 220px;">
							<li>
								<a href="javascript:"  @click="goDetail(item)">자세히보기</a>
							</li>
							<li>
								<a href="javascript:"  @click="goStart(item)">시작하기</a>
							</li>
						</ul>
					</div>
				</swiper-slide>
				<swiper-slide v-if="isMo" class="banner_01" v-for="(item,index) in goods_list" :key="index"  :style="'background-image:url(' + imageSrc + item.goods_img2 + ')'" >
					<div>
						<!-- <h2>원하는 가격으로 <br /><em>구매해 보세요.</em></h2>
						<p>내가 원하는 가격으로 상품을 구매할 수 있어요. <br />원하는 가격을 한번 제시해 보세요.</p> -->
						<ul style="margin-top: 210px;">
							<li>
								<a href="javascript:"  @click="goDetail(item)">자세히보기</a>
							</li>
							<li>
								<a href="javascript:"  @click="goStart(item)">시작하기</a>
							</li>
						</ul>
					</div>
				</swiper-slide>
			</swiper>
			<div class="swiper-button-prev">
				<img src="../assets/common/ico-24-prev.svg" alt="이전" />
			</div>
			<div class="swiper-button-next">
				<img src="../assets/common/ico-24-next.svg" alt="다음" />
			</div>
		<!-- </div> -->

		<div class="inner">
			<div class="top">
				<div class="left" v-if="new_goods_list && new_goods_list.length != 0">
					<div class="title">
						<h3>NEW 상품</h3><span>#매일매일 새로운 상품이 쏟아집니다!</span>
						<!-- <a href="javascript:">더보기</a> -->
					</div>
					<div class="list product_list scroll_area">
						<ol v-for="(item,index) in new_goods_list" :key="index" style="padding-left: 0px;">
							<li>
								<router-link :to="'/product/' + item.goods_code">
									<div class="thumb">
										<img :src="imageSrc + item.goods_thumbnail" alt="썸네일" />
										<!-- <span class="hope"><img src="../assets/common/ico-44-hope.svg" alt="희망거래" /></span> -->
									</div>
									<div class="txt">
										<p>{{item.goods_name}}</p>
										<div class="price">
											<p>종료일 : {{item.end_date}}</p>
											<div>
												<p>{{item.goods_qty - item.sales_sum_qty }}개 남음</p>
												<p><em>{{item.goods_price | makeComma}}</em>원</p>
											</div>
										</div>
									</div>
								</router-link>
							</li>
						</ol>
					</div>
				</div>

				<div class="right" v-if="end_scheduled_goods_list && end_scheduled_goods_list.length != 0">
					<div class="title">
						<h3><em class="blue">종료 예정</em> 상품<span>#서두르세요!</span></h3>
					</div>
					<div class="list product_list swiper swiper_02">
						<div class="swiper-pagination"></div>
						<swiper class="swiper-wrapper" :options="swiperOptions2">
							<swiper-slide class="swiper-slide" v-for="(item,index) in end_scheduled_goods_list" :key="index">
								<router-link :to="'/product/' + item.goods_code">
									<div class="thumb">
										<img :src="imageSrc + item.goods_thumbnail" alt="썸네일" />
										<span class="no dday">D-{{ item.last_day }}</span>
										<span class="hope"><img src="../assets/common/ico-44-hope.svg" alt="희망거래" /></span>
									</div>
									<div class="txt">
										<p>{{item.goods_name}}</p>
										<div class="price">
											<p>종료일 : {{item.end_date}}</p>
											<div>
												<p>{{item.goods_qty - item.sales_sum_qty }}개 남음</p>
												<p><em>{{item.goods_price | makeComma}}</em>원</p>
											</div>
										</div>
									</div>
								</router-link>
							</swiper-slide>

							
						</swiper>
					</div>
				</div>
			</div>

			
		</div>
		<GoodsModal ref="goodsModal" :imgList="imgList" :goodsName="goodsName" ></GoodsModal>
	</div>
</template>

<script>
import { Swiper,SwiperSlide,directive,} from 'vue-awesome-swiper'
import moment from "moment";
import {getEthPrice, postData, showToast} from '../api/serverApi';
import GoodsModal from "@/views/GoodsModal";

export default {
	name: "indexView",
	components: {
		Swiper,
		SwiperSlide,
		GoodsModal
	},
	data() {
		return {
			showShare: false,
			showPopup: false,
			toogle_01: false,
			toogle_02: false,
			swiperOptions: {
				slidesPerView: 1,
				loop: false,
				spaceBetween: 1,
				slidesOffsetAfter : 0, 
				freeMode: true,
        		infiniteMode: true,
				// watchOverflow : true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},	 
			},
			swiperOptions2: {
				slidesPerView: 1,
				loop: false,
				spaceBetween: 24,
				slidesOffsetAfter : 0, 
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					clickable: true,
				},
			},
			goods_list: [],
			new_goods_list: [],
			end_scheduled_goods_list: [],
			imageSrc: '',
			user: {},
			eth_price: 0,
			nowTime: null,
			modalGoods: {},
			imgList:[],
			goodsName:'',
			isMo:false,
		}
	},
	directives: {
		swiper: directive
	},
	mounted() {
		if(window.innerWidth > 1000){
			this.isMo = false;
		}else{
			this.isMo = true;
		}
		console.log(['this.isMo', this.isMo]);
		console.log(['VUE_APP_API_URL', process.env.VUE_APP_API_URL]);
		console.log(['VUE_APP_SITE_CODE', process.env.VUE_APP_SITE_CODE]);
		this.user = localStorage.getItem('user-info');
		console.log(['user', this.user]);
		this.nowTime = moment(new Date()).format('Y.MM.D HH:mm:ss');
		// let IMG_URL = 'https://api.wonhago.com';
		let IMG_URL = process.env.VUE_APP_API_URL;

		if (process.env.NODE_ENV === 'development') {
			// IMG_URL = 'http://localhost:3004';
			// IMG_URL = 'https://stgapi.wonhago.com';
			IMG_URL = 'https://demoapi.wonhago.com';
		}

		this.imageSrc = IMG_URL;

		this.getMain();
		
		if (window.NodeList && !NodeList.prototype.forEach) {
			NodeList.prototype.forEach = Array.prototype.forEach;
		}

		const sliders = document.querySelectorAll('.scroll_area');
		function preventClick(e){
			e.preventDefault();
			e.stopImmediatePropagation();
		}
		let isDown = false;
		let isDragged = false;
		let startX;
		let scrollLeft;

		sliders.forEach(function(slider){
			slider.addEventListener("mousedown", function(e){
				isDown = true;
				slider.classList.add("active");
				startX = e.pageX - slider.offsetLeft;
				scrollLeft = slider.scrollLeft;
			});
		});

		sliders.forEach(function(slider){
			slider.addEventListener("mouseleave", function(e){
				isDown = false;
				slider.classList.remove("active");
			});
		});

		sliders.forEach(function(slider){
			slider.addEventListener("mouseup", function(e){
				isDown = false;
				const elements = slider.querySelectorAll("a");
				if(isDragged){
				for(let i = 0; i<elements.length; i++){
					elements[i].addEventListener("click", preventClick);
				}
				}
				else{
				for(let i = 0; i<elements.length; i++){
					elements[i].removeEventListener("click", preventClick);
				}
				}
				slider.classList.remove("active");
				isDragged =  false;
			});
		});

		sliders.forEach(function(slider){
			slider.addEventListener("mousemove", function(e) {
				if (!isDown) return;
				isDragged =  true;
				e.preventDefault();
				const x = e.pageX - slider.offsetLeft;
				const walk = (x - startX) * 2;
				slider.scrollLeft = scrollLeft - walk;
			});
		});		
	},
	methods: {
		getMain() {
			//이더리움 가격 가저오기 - https://api.upbit.com/v1/ticker?markets=KRW-ETH
			//async 동작
			getEthPrice().then( res => {
				console.log(['res', res]);
				if (res && res.data) {
				console.log(['getEthPrice', res.data[0], res.data[0].trade_price]);
				this.eth_price = res.data[0].trade_price;
				}
			});

			// main list
			const param = {
				// site_code: 'demo',
				// site_code: 'blockchain',
				site_code: process.env.VUE_APP_SITE_CODE,
			}

			postData('/user/mainGoodsList', param).then( res => {
				console.log(['main', res, res.data.success]);
				console.log(['main data', res, res.data]);
				if (res.data.success) {
					this.goods_list = res.data.goods_list;
					this.new_goods_list = res.data.new_goods_list;
					this.end_scheduled_goods_list = res.data.end_scheduled_goods_list;
				}
			})
			
			this.nowTime = moment(new Date()).format('Y.MM.D HH:mm:ss');
		},
		goDetail(item) {
			console.log('goDetail', item);
			if (item.detail_count > 0) {
				this.imgList =[];
				for(var i = 1; i <= item.detail_count ;i++ ){
				  console.log(i);
				  this.imgList.push(this.imageSrc+item.image_path+"detail"+i+item.detail_ext);
				}
				this.modalGoods = item;
				this.goodsName = item.goods_name;
				this.$refs['goodsModal'].$refs["goods-modal"].show();
			} else {
				showToast('안내페이지가 없습니다.');
			}
		},
		goStart(item) {
			console.log('goStart');
			this.$router.push('/product/'+item.goods_code)
		},
	}
}
</script>