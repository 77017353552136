<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header fs-14" v-if="error">
            <slot name="header">
              {{ error }}
            </slot>
          </div>
          <div class="modal-body" v-else>
            <slot name="body">
              <qrcode-stream @decode="onDecode" @init="onInit" ></qrcode-stream>
            </slot>
            <div style="font-size:16px; color: #383838; font-weight: 700; margin-top: 10px;">보낼 주소</div>
            <input type="text" v-model="qrAddress" placeholder="QR 코드를 스캔하거나 주소 입력하세요." style="border-bottom: 1px solid #e3e3e3; height: 24px; width: 100%;  margin-bottom: 10px; border: 1px solid #adb5bd;" />
            <div style="text-align: right; display: flex; justify-content:flex-end; -webkit-box-align:center; align-items: center; " @click.stop="showAmountKeyboard()" v-scroll-to="'.pin-number-keyboard'">
              <b style="font-size: 20px;">{{ krwAmtToComma }}</b>
              <span style="font-size: 15px;  margin-left: 10px; ">KRW</span>
            </div>
            
            <div @click="sendToken()" style="font-size: 16px !important; display : flex; justify-content: center; align-items : center; height: 40px; background-color: #1963ff; color: #fff;">보내기</div>
          </div>
          <amount-keyboard-vue v-if="isAmountKeyboard"  id="test"  @setNumbers="setNumbersFunc" @isAmountkeyboards="isAmountkeyboardFunc"></amount-keyboard-vue>
          <div class="modal-footer">
            <slot name="footer">
              <button class="btn modal-default-button" @click="$emit('close')">
                닫기
              </button>
            </slot>
          </div>
        </div>
      </div>
      
    </div>
  </transition>
</template>

<script>
// import { mapGetters } from 'vuex'
import localKeystore from '@/local/localKeystore.js'
import {buffToHex} from '@/crypto/cryptoutil.js'
import {postData} from '@/api/serverApi';
import AmountKeyboardVue from '@/components/wallet/AmountKeyboardVue'

export default {
  components: {
      AmountKeyboardVue,
  },
  props:{
    initEventInfo:Array,
    initTran:String,
    initToken:Object,
    initAmount:Number,
    initTokenlist:Array,
  },
  data(){
    return {
      error: '',
      result: '',
      eventInfo:this.initEventInfo,
      qrAddress: '',
      user:'',
      tranType:this.initTran,
      amount:this.initAmount,
      numberKeys: null,
      isInputAddress:false,
      sendWitAmt:0,
      sendKrwAmt:0,
      isAmountKeyboard:false,
      tokenlist:this.initTokenlist,
      token:this.initToken
    }
  },
  computed:{
    witAmtToComma(){
      return this.comma(this.sendWitAmt)
    },
    krwAmtToComma(){
      return this.comma(this.sendKrwAmt)
    }
  },
  mounted() {
    console.log(['mounted', process.env.VUE_APP_API_URL]);
    console.log(['tranType', this.tranType]);
    console.log(['this.initTran', this.initTran]);
    console.log(['this.tokenlist', this.initTokenlist]);
    console.log(['this.amount', this.initAmount]);
    console.log(['this.initToken', this.initToken]);
    this.user = localStorage.getItem('user-info');
    this.$emit("transResult", 'fail');
  },
  methods:{
    onDecode (result) {
      alert(result);
      this.qrAddress = result;
      // this.$store.commit(this.$types.QR_ADDRESS, result);
      // this.$emit('close')
    },
    sendToken (){
      if(this.qrAddress == ''){
        alert("주소를 입력해주세요.");
      }else{
        console.log(["typeof(this.amount)",typeof(this.amount), this.amount ]);
          console.log(["typeof(this.amount)",typeof(this.sendKrwAmt), this.sendKrwAmt]);
        //가지고 있는 토큰과 비교해서 넘는지 확인
        if(this.amount < this.sendKrwAmt){
          alert("보낼 금액보다 잔고가 부족합니다.");
          return;
        }else if(this.amount == 0){
          alert("잔고가 부족합니다.");
          return;
        }

        if (confirm(this.qrAddress +"의 주소로 토큰 " +this.sendKrwAmt+"개를 전송하시겠습니까?")) {
          //넘지 않으면 각토큰별로 가지고 있는 값들 비교해서 이벤트 블럭 만들기
          // returnOutputsCnt, isLast
          let eventInfo = [];

          //계산을 위한 임시 총합
          var tempSendKrwAmt = this.sendKrwAmt;
          tempSendKrwAmt = Number(tempSendKrwAmt);
          this.tokenlist.forEach(block => {
            console.log(["typeof(tempSendKrwAmt)", typeof(tempSendKrwAmt),tempSendKrwAmt ]);
            console.log(["typeof(block.amount)", typeof(block.amount), block.amount ]);
            console.log(["tempSendKrwAmt- block.amount", tempSendKrwAmt- block.amount ]);
            console.log(["Number(tempSendKrwAmt) - block.amount", Number(tempSendKrwAmt) - block.amount]);
            if(tempSendKrwAmt <= 0 ){
              return false;
            }

            if((tempSendKrwAmt - block.amount) > 0){
              //다음 또 진행 isLast flase
              console.log(["(tempSendKrwAmt - block.amount) > 0"]);
              eventInfo.push({'eventHash':"",'blockHash':block.tokenId, 'definition_id':block.definitionId, 'transaction_hash':block.txHash, 'event_name':"", 'block_records_idx' : "", 'block_level' : 0, 'outputsCnt': block.amount, isLast:false , returnOutputsCnt:0 });
            }else if((tempSendKrwAmt - block.amount) == 0){
              //진행 종료 isLast flase
              console.log(["(tempSendKrwAmt - block.amount) == 0"]);
              eventInfo.push({'eventHash':"",'blockHash':block.tokenId, 'definition_id':block.definitionId, 'transaction_hash':block.txHash, 'event_name':"", 'block_records_idx' : "", 'block_level' : 0, 'outputsCnt': block.amount, isLast:false , returnOutputsCnt:0 });
            }else if((tempSendKrwAmt - block.amount) < 0){
              //진행 종료 isLast true
              console.log(["(tempSendKrwAmt - block.amount) < 0"]);
              eventInfo.push({'eventHash':"",'blockHash':block.tokenId, 'definition_id':block.definitionId, 'transaction_hash':block.txHash, 'event_name':"", 'block_records_idx' : "", 'block_level' : 0, 'outputsCnt': tempSendKrwAmt, isLast:true , returnOutputsCnt:block.amount-tempSendKrwAmt });
            }
            tempSendKrwAmt = tempSendKrwAmt- block.amount;

            
          });
          console.log(["eventInfo",eventInfo]);

          

          const pinNumberKey = 'WNS_PIN_NUMBER'; //키값으로 로컬 스토리지에 저정
          const pinNumber = sessionStorage.getItem(pinNumberKey);
          console.log('pinNumber : ' +pinNumber);

          const user = JSON.parse(this.user);
          console.log('user : ' +user);

          localKeystore.getPrivateKey(pinNumber).then( retunrpk => {
            console.log(['getPrivateKey', buffToHex(retunrpk)]);


            const data = {
              eventInfo : JSON.stringify(eventInfo),
              qrAddress : this.qrAddress,
              privateKey : buffToHex(retunrpk),
              user_code : user.user_code,
              tranType : "TOKEN",
            };
            console.log(data);

            postData('/user/postTransactionTransferToken', data).then( res => {
              console.log(['postTransactionTransferToken', res, res.data.success]);
              console.log(['postTransactionTransferToken', res, res.data]);
              if (res.data.success) {
                alert("전송되었습니다.");
                this.$emit("transResult", 'success');
              }else{
                alert(res.data.errorMassage);
                this.$emit("transResult", 'fail');
              }
            })

          }).catch( err => {
            console.log(err);
          })
        }
      }
    },
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.error = "You need to grant camera access permisson."
        } else if (error.name === 'NotFoundError') {
          this.error = "No camera on this device."
        } else if (error.name === 'NotSupportedError') {
          this.error = "Secure context required (HTTPS, localhost)."
        } else if (error.name === 'NotReadableError') {
          this.error = "Is the camera already in use?"
        } else if (error.name === 'OverconstrainedError') {
          this.error = "Installed cameras are not suitable."
        } else if (error.name === 'StreamApiNotSupportedError') {
          this.error = "Stream API is not supported in this browser."
        }
      }
    },
    showAmountKeyboard(){
      this.isAmountKeyboard = true;
      // this.$store.commit('IS_AMOUNT_KEYBOARD', true)
    },
    focusQrInput(state){
      // this.isInputAddress = state
      this.isAmountKeyboard = false;
      // this.$store.commit('IS_AMOUNT_KEYBOARD', false)
    },
    comma(obj){ 
      return this.formatNumber(obj); 
    },
    formatNumber(obj){
      if (obj){
        const regx = new RegExp(/(-?\d+)(\d{3})/);
        let bExists = obj.toString().indexOf('.', 0)
        let strArr = obj.toString().split('.')
        while (regx.test(strArr[0])) {
          strArr[0] = strArr[0].replace(regx, "$1,$2")
        }
        if (bExists > -1) {
          obj = strArr[0] + '.' + strArr[1]
        } else {
          obj = strArr[0]
        }
        return obj
      }else{
        return '0'
      }
    },
    setNumbersFunc(v){
      console.log(v);

      const key = v
      const amount = (this.sendKrwAmt||'0') === '0' ? '': this.sendKrwAmt
      if (key !== null){
        switch (key) {
          case 'backspace':
            this.sendKrwAmt = amount.slice(0, -1)||0  
            break;
          case 'dot':
            if (amount.lastIndexOf('.') <= -1 )
            this.sendKrwAmt  = (amount||'0')  + v
            break;
          case 'Next':
            //router.push('/success')
            console.log("next")
            console.log(this.sendKrwAmt);
            console.log(this.amount);
            this.sendToken();
            break;
          default:
            this.sendKrwAmt  = amount  + v
            break;
        }
     
        return this.comma(this.sendKrwAmt);
      }
    },
    isAmountkeyboardFunc(v){
      this.isAmountKeyboard = v;
    },

  }
}
</script>

<!-- <style lang="scss" scoped>
  .pin-number-keyboard {
    span{
      font-size: rem(20) !important;
      line-height: rem(41);
    }

    div{
      width: 33%;
      float: left;
      height: rem(52);
      border: solid rem(0.1) rgba(168, 195, 255, 0.5);
      background-color: #1963ff;
      font-size: rem(20);
      color: #fff;
    }
  }
</style> -->