<template>
  <container-vue name="login-app" @click.native="isPinCodeState = false">
    
    <main>
      <col-vue cols="12">
        <row-vue ml="24" mt="86">
          <col-vue cols="12" class="text-primary fs-32" style="font-size: 1.8rem;">
            전자지갑에 로그인하기
          </col-vue>
          <col-vue cols="12" class="text-dark-gray mt-8" style="font-size: 1rem;">
            6자리 PIN 번호를 입력해 주세요.
          </col-vue>
        </row-vue>
        <row-vue mt="32" class="mx-15">
          <div class="login-pin-number col-12" @click.stop="toggle()">
            <div class="text-center" v-for="(v, idx) in 6" :key="v" >
                <span v-html="pinNumber[idx] && pinNumber[idx].text != null? pinNumber[idx].text: '&nbsp;'" class="fs-20"></span>
            </div>
          </div>  
        </row-vue>
      </col-vue>
    </main>
    <footers>
      <div class="pin-number-keyboard mt-50" v-if="isPinCodeState === true" @click.stop="toggle()"> 
        <div class="text-center" v-for="v in numberKeys" :key="v" @click="setPinNumber(v)" style="display : flex; justify-content: center; align-items : center;">
          <div v-if="v === 'backspace'" class="icon backspace-white" style="background-color: rgb(25, 99, 255); width:40px; height: 21px;  text-align: center !important;  "></div>
          <span v-else>{{ v }}</span>
        </div>
      </div>
      <div class="create-wallet text-center" v-else @click="$router.push('join')">
        새로운 전자지갑 생성하기
      </div>
    </footers>
  </container-vue>
  
</template>

<script>
//   import * as types from '@/store/mutation-types'
// import { setTimeout } from 'timers';
  import localKeystore from '../local/localKeystore.js'

  export default {
    name: 'loginView',

    data(){
      return {
        isPinCodeState: false,
        numberKeys: null,
        pinNumber: [],
        error: null
      }
    },
    created() {
      let arrKey = []
      for (let i=1; i <= 9; i++){
        arrKey.push(i)
      }
      this.numberKeys = arrKey.concat(['', 0, 'backspace'])
    },
    methods: {
      toggle(){
        this.isPinCodeState = true
      },
      hide() {
        this.isPinCodeState = false
      },
      setPinNumber(val){
        if (val === 'backspace'){
          this.pinNumber.pop()
        }else if(this.pinNumber.length < 6 && val !== null){
          this.pinNumber.forEach((v, k) => {
            const pinVal = this.pinNumber[k].value
            this.pinNumber[k] = {
              text: '*',
              value: pinVal 
            }
          })
          this.pinNumber.push({
            value: Number(val),
            text: Number(val),
          })
          // 6자리 일때 LOGIN action call
          if (this.pinNumber.length >= 6){
            // setTimeout(() => {
               console.log(['try to login with pin number ', this.pinNumber])
              localKeystore.verify(this.pinNumber.map((v)=> v.value).join('')).then(
                (result) => {
                  if(result){
                    console.log(['login trial result: true'])
                    console.log(['login trial result: true', this.pinNumber.map((v)=> v.value).join('')])
                    const AUTH_STORAGE_KEY = 'WNS_PIN_NUMBER'
                    sessionStorage.setItem(AUTH_STORAGE_KEY, this.pinNumber.map((v)=> v.value).join(''));
                    sessionStorage.setItem("kycUse", "N");
                    this.$router.push('/');
                  }else{
                    console.log(['login trial result: false'])
                    //임시로 핀번호만 삭제
                    this.pinNumber = []
                  }
                }
              )
              

              // this.$store.dispatch(this.$types.LOGIN, {
              //   pinNumber: this.pinNumber.map((v)=> v.value).join('')
              // })
              // .then((result) => {
              //   if (result.resultCode === 'suc'){
                  
              //     // 로딩 이미지 보이기
              //     this.$store.commit(this.$types.LOGIN_SUCCESS, {
              //       pinNumber: this.pinNumber.map((v)=> v.value).join('')
              //     })
                
              //   }else{
              //     //임시로 핀번호만 삭제
              //     this.pinNumber = []
              //   }
              // })
             
            // }, 1000);
          }
        }
      },
    },
  }
</script>

<style lang="scss" >

  #login-app{
    
    .container{
      display: flex;
      width: 100vw;
      height: 100vh;
      flex-direction: column;
    }

    padding-top: 54px !important;

    header{
      position: fixed;
      top: 0;
      width: 100vw;
      box-sizing: border-box;
      background-color: #fff;
    }

    
    main{  
      .error-alert{
        display: none;
      }

      &.error{
        .row:not(:first-child) span{
          color: #f74c26 !important; 
        }
        .error-alert{
          display: flex;
        }
      }


      .err-box{
        align-items: center;
        border-radius: 4px;
        border: solid 1px #e3e3e3;
        background-color: #fafcfd;
        height: 40px;
        object-fit: contain;
        color: #f74c26;
      }

      .login-pin-number {
        div {
          span{
            font-size: 40px !important;
          }
          width: 16.6%;
          float: left;
          height: 64px;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 10%;
            width: 80%;
            border-bottom: 1px solid #b7b7b7 !important;
          }
        }

        &.active{
          div {
            &:after{
              border-bottom: 1px solid #1963ff;
            }
          }
        }
      }
    }
    
    footers{
      margin: 50px;
      .signup {
        min-height: 52px;
        color: #fff;
        background-color: #e3e3e3;

        &.active{
          background-color: #1963ff; 
        }
      }
      .pin-number-keyboard {
        span{
          font-size: 20px !important;
          line-height: 41px;
          color: #fff;
        }

        div{
          width: 33%;
          float: left;
          height: 52px;
          border: solid 0.1px rgba(168, 195, 255, 0.5);
          background-color: #1963ff;
          font-size: 20px;
          color: #fff;
        }
      }
        
      .create-wallet {
        height: 64px;
        color: #1963ff;
        font-size: 18px;
        border: solid 1px #1963ff;
      }
    }

    
  }
</style>
