//import { buffToHex } from '@/crypto/cryptoutil.js'

const isEqual = require('arraybuffer-equal');

export function getKeyMaterial(password) {
  return window.crypto.subtle.importKey(
    "raw",
    new TextEncoder().encode(password),
    {name: "PBKDF2"},
    false,
    ["deriveBits", "deriveKey"],
  );
}

/*
  salt: Uint8Array(16)
    example) window.crypto.getRandomValues(new Uint8Array(16));
 */
export function getKey(keyMaterial, salt) {
  return window.crypto.subtle.deriveKey(
    {
      "name": "PBKDF2",
      salt: salt,
      "iterations": 100000,
      "hash": "SHA-256"
    },
    keyMaterial,
    { "name": "AES-GCM", "length": 256},
    true,
    [ "encrypt", "decrypt" ],
  );
}

const additionalData = new TextEncoder().encode('additional data');

/*
  iv: initial vector (Uint8Array(12))
    example) window.crypto.getRandomValues(new Uint8Array(12));
 */
export async function encrypt(key, iv, messageArray) {
  return await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
      additionalData: additionalData,
    },
    key,
    messageArray
  );
}

export async function decrypt(key, iv, ciphertext) {
  return await window.crypto.subtle.decrypt(
    {
      name: "AES-GCM",
      iv: iv,
      additionalData: additionalData,
    },
    key,
    ciphertext
  );
}

export async function getMac(key, iv) {
  return await window.crypto.subtle.encrypt(
    {
      name: "AES-GCM",
      iv: iv,
    },
    key,
    additionalData
  );
}

export async function verifyMac(key, iv, mac) {
  return isEqual((await getMac(key, iv)), mac);
}
