<template>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header fs-14" v-if="error">
              <slot name="header">
                {{ error }}
              </slot>
            </div>
            <div class="modal-body" v-else>
              <div>
                <div style="align-items: center; text-align: center; font-size:18px !important; font-weight: 400; color: #383838; ">
                  배송 상태
                </div>
                <div style="font-size:16px; color: #383838; font-weight: 700;">
                    현재 배송상태 : {{ purchase.purchase_status_str }}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="btn modal-default-button" @click="$emit('close')">
                  닫기
                </button>
              </slot>
            </div>
          </div>
        </div>
        
      </div>
    </transition>
  </template>
    
  <script>
  
  export default {
    name: "StateModal",
    props: {
      initPurchase: Object,
    },
    data() {
      return {
        user: {},
        purchase: this.initPurchase
      }
    },
    components: {},
    created() {
      console.log(["StateModal created"]);
      
    },
    mounted() {
      console.log("StateModal mounted");
      this.user = localStorage.getItem('user-info');
      
    },
    methods: {
        
    },
    updated() {
      console.log("StateModal updated");
      // for(var i = 1; i <= this.goods.detail_count ;i++ ){
      //   console.log(i);
      //   this.imgList.push(this.imageSrc+this.goods.image_path+"detail"+i+this.goods.detail_ext);
      // }
      // console.log(["goodsModal",this.imgList]);
    }
  }
  </script>
  
  <style scoped>
  
  </style>
    