import router from '@/router';
const elliptic = require('elliptic');
const ec = new elliptic.ec('secp256k1');
const { keccak_256 } =  require('js-sha3');
const { map, splitEvery }  = require('rambda');
import { postData } from '@/api/serverApi';
// import apiServer from '@/api/serverApi';

const deviceKey = 'device-info';
const userKey = 'user-info';

const hexStringToArray = (hexString) => {
  return map((hex) => parseInt(hex, 16))(
    splitEvery(2)(hexString)
  );
};

router.beforeEach(async (to, from, next) => {
  console.log(['before', to, from, next, process.env]);
  console.log(['env', process.env.VUE_APP_API_URL]);

  const privateKey = localStorage.getItem(userKey)
  console.log(['first init', privateKey]);
  if (privateKey) {
    console.log('있음')
  } else {
    // key pair 생성
    let keyPair = ec.genKeyPair(); // Generate random keys
    let privKey = keyPair.getPrivate("hex");
    let pubKey = keyPair.getPublic().x.toString('hex') + keyPair.getPublic().y.toString('hex');

    const publicKeyHash = keccak_256(Uint8Array.from(hexStringToArray(pubKey)));
    // const pubHash = publicKeyHash.substring(publicKeyHash.length);
    let address = publicKeyHash.substring(publicKeyHash.length - 40);
    console.log(['key pair', keyPair, privKey, address]);
    localStorage.setItem(deviceKey, privKey);  // key를 저장해둔다.

    // const param = {device_id: privKey, site_code: 'blockchain'};
    const param = {device_id: privKey, site_code: 'demo'};
    //사용자 등록
    const result = await postData('/user/registerDevice', param);
    console.log(['regiserDevice result', result]);
    if (result.data.success) {
      const user = result.data.info[0];
      localStorage.setItem(userKey, JSON.stringify(user));

      /*
      // blockchain에 계정을 생성한다.
      const value = {
        "type" : "CreateName",
        "value" : {
          "networkId" : 102,
          "createdAt" : new Date(),
          "name" : user.user_code,//account
          "state" : {
            "addressess" : {
              [address] : 1
            },
            "threshold" : 1,
            "guardian" : null
          }
        }
      }

      const txResult = await postBBGO('/txhash', value);
      // const txResult = await apiServer.txhash(value);

      console.log(['txResult', txResult]);

      if (txResult.status === 200) {
        const resHash = txResult.data;
        const keyPair = ec.keyFromPrivate(privKey);
        let ecSign = ec.sign(resHash, keyPair);
        const r = ecSign.r.toString(16);
        const s = ecSign.s.toString(16);
        const v = ecSign.recoveryParam + 27;

        const param = {
          "sig" : {
            "NamedSignature" : {
              "name" : user.user_code,
              "sig" : {
                "v" : v,
                "r" : r,
                "s" : s
              }
            } },
          value
        }

        const tansResult = await postBBGO('/transaction', param);

        console.log(['tansResult', tansResult]);

      } else {
        console.error(['hash error'])
      }
      */

    } else {

      console.error(['device reg error']);

    }
  }

  next();
});
export default router;
