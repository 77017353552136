<template>
  <div>
    <b-modal ref="event-modal" :title="event.event_name" title-class="" ok-only ok-title="닫기">
      <img :src="imageSrc + event.detail_image">
      <!--      <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close Me</b-button>-->
      <!--      <b-button class="mt-2" variant="outline-warning" block @click="toggleModal">Toggle Me</b-button>-->
    </b-modal>
  </div>

</template>

<script>
export default {
  name: "EventModal",
  props: ['openModal', 'imageSrc', 'event'],
  data() {
    return {
      user: {}
    }
  },
  components: {},
  created() {

  },
  mounted() {
    this.$log.debug(['item', this.item]);
    this.user = localStorage.getItem('user-info');
  },
  methods: {

  }
}
</script>

<style scoped>

</style>
