<template>
  <div id="app">
    <HeaderTop />
    <section id="content" class="content">
      <router-view v-slot="{ Component }">
        <transition name="fade" mode="out-in">
          <!-- <component :is="Component" key="route.path" /> -->
          <component :is="Component" />
        </transition>
      </router-view>
    </section>
    <Footer />
  </div>
</template>
<script src="//developers.kakao.com/sdk/js/kakao.min.js"></script>
<script>
// import Header from './components/layout/header.vue';
import Footer from './components/layout/footer.vue';
import HeaderTop from "@/components/layout/header";

export default {
  name: 'App',
  components: {
    HeaderTop,
    Footer,
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*  margin-top: 60px;*/
/*}*/
</style>
