<template>
	<div id="wallet" class="wallet">
		<div class="wallet_list">
			<div class="top">
				<span style="float: left; display: block;"><h2>총 보유 금액</h2></span>
				<span style="float: right; display: block;">
					<button @click="showAddressQrModal=true" style="color: #fff;">
						<!-- <img src="../assets/common/ico-16-download-white.svg" alt="받기" style="vertical-align: middle;" /> -->
						지갑으로 받기
					</button>
				</span>
				<!-- <h2>총 보유 금액</h2> -->
				<div>
					<div>
						<span>KRW</span>
						<p style="margin-bottom: 0;">{{ totalCountKrw.toFixed(0) | makeComma }}</p>
						<a href="javascript:"><img src="../assets/common/ico-16-refresh-w.svg" alt="새로고침" @click="getWallet()"/></a>
					</div>
					<p>ETH {{ totalCountETH.toFixed(4) }}</p>
				</div>
			</div>
			<!-- <div class="list swiper">
				<ul class="swiper-wrapper">
					<li class="swiper-slide">
						<div class="qr">
							<p class="img"><img src="../assets/common/qr.svg" alt="qr" /></p>
							<p class="txt">3Ddfex34tDF33ddjTc.</p>
						</div>
						<div class="card">
							<div class="top">
								<p>내 꿈이닷! <span>(신한 123-546-345)</span></p>
								<router-link to="/wallet-viewitem"><img src="../assets/common/ico-24-settings.svg" alt="설정" /></router-link>
							</div>
							<div class="money">
								<div>
									<span>WT</span>
									<p>54,000,500</p>
									<a href="javascript:"><img src="../assets/common/ico-16-refresh.svg" alt="새로고침" /></a>
								</div>
								<p>KRW 54,000,500</p>
							</div>
							<ul>
								<li><a href="javascript:"><img src="../assets/common/ico-16-upload.svg" alt="보내기" /><span>보내기</span></a></li>
								<li><a href="javascript:"><img src="../assets/common/ico-16-download.svg" alt="받기" /><span>받기</span></a></li>
							</ul>
						</div>
					</li>

					<li class="swiper-slide">
						<div class="qr">
							<p class="img"><img src="../assets/common/qr.svg" alt="qr" /></p>
							<p class="txt">3Ddfex34tDF33ddjTc.</p>
						</div>
						<div class="card">
							<div class="top">
								<p>내 꿈이닷! <span>(신한 123-546-345)</span></p>
								<router-link to="/wallet-viewitem"><img src="../assets/common/ico-24-settings.svg" alt="설정" /></router-link>
							</div>
							<div class="money">
								<div>
									<span>WT</span>
									<p>54,000,500</p>
									<a href="javascript:"><img src="../assets/common/ico-16-refresh.svg" alt="새로고침" /></a>
								</div>
								<p>KRW 54,000,500</p>
							</div>
							<ul>
								<li><a href="javascript:"><img src="../assets/common/ico-16-upload.svg" alt="보내기" /><span>보내기</span></a></li>
								<li><a href="javascript:"><img src="../assets/common/ico-16-download.svg" alt="받기" /><span>받기</span></a></li>
							</ul>
						</div>
					</li>

					<li class="swiper-slide">
						<div class="add_card">
							<router-link to="/wallet-make">
								<p><img src="../assets/common/btn-add.svg" alt="추가" /></p>
								<p>지갑에 사용하는 계좌를 <br>추가할 수 있습니다.</p>
							</router-link>
							<a href="javascript:"><img src="../assets/common/ico-24-settings.svg" alt="설정" /></a>
						</div>
					</li>
				</ul>
				<div class="swiper-pagination"></div>
			</div> -->
			<div class="list swiper-container" style="padding-bottom: 50px; margin-left: 24px; ">
				<swiper class="swiper-wrapper" :options="swiperOptions">
					<swiper-slide class="swiper-slide">
						<div class="qr">
							<!-- <p class="img"><img src="../assets/common/qr.svg" alt="qr" /></p> -->
							<span class="txt">토큰지갑</span>
						</div>
						<div class="card">
							<div class="top">
								<!-- <p>내 꿈이닷! <span>(신한 123-546-345)</span></p>
								<router-link to="/wallet-viewitem"><img src="../assets/common/ico-24-settings.svg" alt="설정" /></router-link> -->
							</div>
							<div class="money">
								<div>
									<span>WT</span>
									<p>{{token.totalToken | makeComma}}</p>
									<a href="javascript:" ><img src="../assets/common/ico-16-refresh.svg" alt="새로고침" @click="getWallet()"/></a>
								</div>
								<p>KRW {{token.totalToken | makeComma}}</p>
							</div>
							<ul>
								<li><a href="javascript:" @click="showSendQrModalFunc"><img src="../assets/common/ico-16-upload.svg" alt="보내기" /><span>보내기</span></a></li>
								<li><a href="javascript:" @click="showAddressQrModal=true"><img src="../assets/common/ico-16-download.svg" alt="받기" /><span>받기</span></a></li>
							</ul>
						</div>
					</swiper-slide>
					<swiper-slide class="swiper-slide" v-for="(event, i) in eventList" :key="i">
						<div class="qr">
							<!-- <span class="txt">{{event.event.event_name}}</span> -->
							<span class="right" v-if="event.isFinal">
								<button class="qr-button" @click="qrModal(event.event.event_hash,'','',event.event.definition_id, event.event.event_name,'event','','')">
									<!-- <img src="../assets/common/ico-16-upload-white.svg" alt="보내기" style="vertical-align: middle;" /> -->
									보내기
								</button>
							</span>
							<span class="right" v-if="!event.isFinal">
								<button class="qr-button" @click="sendAlert()">
									<!-- <img src="../assets/common/ico-16-upload-white.svg" alt="보내기" style="vertical-align: middle;" /> -->
									보내기
								</button>
							</span>
						</div>
						<div class="eventImage">
							<div class="bottom detail-image">
								<ul style="margin-bottom: 0; padding-left: 0;">
									<li class="block-wrap" :class="'block-' + event.eventBlockList.length" v-for="(block, i) in event.eventBlockList" :key="i">
										<!-- <a class="hint-wrap" v-if="block.block_find" @click="qrModal(block.event_hash,block.block_hash, block.transaction_hash,event.event.definition_id,event.event.event_name,'block', block.block_records_idx, block.block_level)" href="javascript:" style="margin-left: 5px; margin-top: 5px;">
											<img src="../assets/common/ico-16-upload-white.svg"/>
											<span style="color: #fff;">블록 보내기</span>
										</a> -->
										<a v-if="block.block_find"  @click="blockInfo(block.transaction_hash, block.created_at)" href="javascript:" style="margin-left: 5px; margin-top: 5px; z-index: 3; position: absolute;">
											<img style="width: 24px; height: 24px;" src="../assets/common/wd-accent-blockchain.svg"/>
										</a>
										<div class="img-wrap" >
											<img :src="imageSrc+event.event.image_path + (i+1) + '.' + event.event.image_ext" alt="" :class="!block.block_find ? 'disable': ''" />
										</div>
									</li>
								</ul>
							</div>
						</div>
					</swiper-slide>
					<swiper-slide class="swiper-slide" v-for="(purchase, i) in purchasesList" :key="i">
						<div class="qr">
							<!-- <span class="txt">{{purchase.purchase_name}}</span> -->
							<span class="right" v-if="'PURCHASE_STATUS01' == purchase.purchase_status">
								<button class="qr-button" @click="qrModal('',purchase.token_id, purchase.transaction_hash,purchase.definition_id,purchase.purchase_name,'product', purchase.purchases_idx, '')"  >
									<!-- <img src="../assets/common/ico-16-upload-white.svg" alt="보내기" style="vertical-align: middle;" /> -->
									보내기
								</button>
							</span>
							<span class="left" v-if="'PURCHASE_STATUS01' == purchase.purchase_status && ('DELIVERY_TYPE02' == purchase.delivery_type || 'DELIVERY_TYPE03' == purchase.delivery_type) ">
								<button class="qr-button" @click="inputModal(purchase.purchases_idx, purchase.delivery_type)" v-if="'DELIVERY_TYPE02' == purchase.delivery_type">
									<!-- <img src="../assets/common/ico-16-edit.svg" alt="보내기" style="vertical-align: middle;" /> -->
									쿠폰 요청
								</button>
								<button class="qr-button" @click="inputModal(purchase.purchases_idx, purchase.delivery_type)" v-if="'DELIVERY_TYPE03' == purchase.delivery_type">
									<!-- <img src="../assets/common/ico-16-edit.svg" alt="보내기" style="vertical-align: middle;" /> -->
									배송 요청
								</button>
							</span>
							<span class="right" v-if="'PURCHASE_STATUS01' != purchase.purchase_status">
								<button class="qr-button" @click="stateModal(purchase)"  >
									<!-- <img src="../assets/common/ico-16-upload-white.svg" alt="보내기" style="vertical-align: middle;" /> -->
									배송상태
								</button>
							</span>
						</div>
						<div class="eventImage">
							<div class="bottom detail-image">
								<ul style="margin-bottom: 0; padding-left: 0;">
									<li class="block-wrap" style="width: 100%;">
										
										<div class="img-wrap" >
											<a  @click="blockInfo(purchase.transaction_hash, purchase.created_at)" href="javascript:" style="margin-left: 5px; margin-top: 5px;  z-index: 3; position: absolute;">
												<img style="width: 24px; height: 24px;" src="../assets/common/wd-accent-blockchain.svg"/>
											</a>
											<img :src="imageSrc+purchase.goods_thumbnail" alt="" />
										</div>
									</li>
									
								</ul>
								<!-- <a class="hint-wrap" @click="blockInfo(purchase.transaction_hash)" href="javascript:" style="margin-left: 5px; margin-top: 5px;">
									<img src="../assets/common/ico-16-blockchain.svg"/>
								</a> -->
								
							</div>
						</div>
					</swiper-slide>
					
				</swiper>
				<div class="swiper-pagination"></div>
			</div>
		</div>

		<div class="menu_list">
			<ul>
				<li>
					<!--<router-link to="./wallet-list">-->
					<a href="javascript:">
						<div>
							<p><img src="../assets/common/ico-24-paper.svg" alt="이용 내역" /><span>이용 내역</span></p>
							<p><img src="../assets/common/ico-22-arrow.svg" alt="이동" /></p>
						</div>
					</a>
				</li>
				<li>
					<a href="javascript:">
						<div>
							<p><img src="../assets/common/ico-24-webcam.svg" alt="부가 서비스" /><span>부가 서비스</span></p>
							<p><img src="../assets/common/ico-22-arrow.svg" alt="이동" /></p>
						</div>
					</a>
				</li>
			</ul>
		</div>
		<qrcode-modal-vue  v-if="showModal" @transResult="transResultFunc" @close="qrModalClose" :initEventInfo="eventInfo" :initTran="tranType" ></qrcode-modal-vue>
		<address-qr-modal-vue  v-if="showAddressQrModal"  @close="showAddressQrModal=false"></address-qr-modal-vue>
		<send-qr-modal-vue  v-if="showSendQrModal" @transResult="transResultFunc" @close="SendQrModalClose" :initAmount="amount" :initTokenlist="tokenList" :initToken="token" :initTran="tranType"></send-qr-modal-vue>
		<input-modal-vue  v-if="showInputModal" @transResult="transResultFunc" @close="InputModalClose" :initPurchasesIdx="purchasesIdx" :initDeliveryType="deliveryType" ></input-modal-vue>
		<state-modal-vue  v-if="showStateModal" @close="stateModalClose" :initPurchase="purchase" ></state-modal-vue>
		<block-chain-info-modal-vue  v-if="showBlockChainInfoModal" @close="showBlockChainInfoModalClose" :initTransactionHash="tranHash" :initCreated_at="created_at"></block-chain-info-modal-vue>
	</div>
</template>


<script>
import {getEthPrice} from '@/api/serverApi';
import {postData} from '@/api/serverApi';
import { Swiper,directive, SwiperSlide } from 'vue-awesome-swiper'
import QrcodeModalVue from '@/components/wallet/QrcodeModalVue' 
import AddressQrModalVue from '@/components/wallet/AddressQrModalVue'
import SendQrModalVue from '@/components/wallet/SendQrModalVue'
import InputModalVue from '@/components/wallet/InputModalVue'
import StateModalVue from '@/components/wallet/StateModalVue'
import BlockChainInfoModalVue from '@/components/wallet/BlockChainInfoModalVue'


export default {
    name: "walletView",
	components: {
		Swiper,
		SwiperSlide,
		QrcodeModalVue,
		AddressQrModalVue,
		SendQrModalVue,
		InputModalVue,
		StateModalVue,
		BlockChainInfoModalVue
	},
	directives: {
		swiper: directive
	},
	data() {
		return {
			imageSrc: '',
			swiperOptions: {
				slidesPerView: 1.2,
				loop: false,
				spaceBetween: 24,
				slidesOffsetAfter : 0, 
				pagination: {
					el: '.swiper-pagination',
					type: 'bullets',
					clickable: true,
				},
			},
			eventList : [],
			purchasesList : [],
			totalCountKrw : 0,
			totalCountETH : 0,
			eth_price: 0,
			showModal: false,
			showAddressQrModal: false,
			showSendQrModal: false,
			showBlockChainInfoModal: false,
			eventInfo:[],
			transResultParam:'fail',
			tranType:'',
			token:{},
			amount:0,
			tokenList:[],
			showInputModal: false,
			purchasesIdx: '',
			deliveryType: '',
			showStateModal: false,
			purchase: {},
			tranHash:'',
			created_at:''
		}
	},
	mounted() {
		console.log(['mounted', process.env.VUE_APP_API_URL]);
		this.user = localStorage.getItem('user-info');
		console.log(['user', this.user]);
		// let IMG_URL = 'https://api.wonhago.com';
		let IMG_URL = process.env.VUE_APP_API_URL;

		if (process.env.NODE_ENV === 'development') {
			// IMG_URL = 'http://localhost:3004';
			IMG_URL = 'https://stgapi.wonhago.com';
		}

		this.imageSrc = IMG_URL;

		this.getWallet();

	},
	methods: {
		Movemove() {
			this.$router.push('/join');
		},
		getWallet() {//지갑 정보 가져오기
			this.totalCountKrw = 0;
			this.totalCountETH = 0;
			this.eth_price = 0;

			//이더리움 가격 가저오기 - https://api.upbit.com/v1/ticker?markets=KRW-ETH
			//async 동작
			getEthPrice().then( res => {
				console.log(['res', res]);
				if (res && res.data) {
				console.log(['getEthPrice', res.data[0], res.data[0].trade_price]);
				this.eth_price = res.data[0].trade_price;
				}
			});

			let userInfo = null;
			if (this.user) {
				userInfo = JSON.parse(this.user);

			}
			const params = {
				user_code : userInfo ? userInfo.user_code : null
			}

			postData('/user/getbalance', params).then( res => {
				console.log(['getbalance res, res.data', res, res.data]);
				console.log(['getbalance res.data.success', res, res.data.success]);
				// console.log(['getbalance res.data.purchase_list[0].purchase_status', res, res.data.purchase_list[0].purchase_status]);
				if (res.data.success) {
					this.eventList = res.data.events_list;
					this.purchasesList = res.data.purchases_list;
					this.token = res.data.token;
					
					console.log(['this.eventList', this.eventList]);
					console.log(['this.eventList.length', this.eventList.length]);
					this.eventList.forEach(event => {
						
						console.log(['event.entry.cnt', event.entry.cnt]);
						console.log(['event.event.payment / event.entry.cnt', (event.event.payment / event.entry.cnt)]);
						console.log(['event.event.payment / this.eth_price', (event.event.payment / this.eth_price)]);

						this.totalCountKrw += (event.event.payment / event.entry.cnt);
						this.totalCountETH += (event.event.payment / event.entry.cnt / this.eth_price);
					});
					this.totalCountKrw += this.token.totalToken;
					this.totalCountETH += (this.token.totalToken / this.eth_price);
				}
			})
		},
		qrModal(eventHash, blockHash, transaction_hash, definition_id, event_name, sendType, block_records_idx, block_level, ){
			this.eventInfo = [];
			this.tranType = '';
			if(sendType == 'block'){
				console.log(['sendType', sendType]);
				this.eventInfo.push({'eventHash':eventHash,'blockHash':blockHash, 'definition_id':definition_id, 'transaction_hash':transaction_hash, 'event_name':event_name, 'block_records_idx' : block_records_idx, 'block_level' : block_level, 'outputsCnt': 1  });
				this.tranType = 'NFT';
			}else if(sendType == 'event'){
				console.log(['sendType', sendType]);
				this.tranType = 'NFT'
				this.eventList.forEach(event => {
					console.log(['event.event.event_hash', event.event.event_hash]);
					console.log(['eventHash', eventHash]);
					if(event.event.event_hash == eventHash){
						event.eventBlockList.forEach(eventBlock => {
							this.eventInfo.push({'eventHash':eventBlock.event_hash,'blockHash':eventBlock.block_hash, 'definition_id':definition_id,  'transaction_hash':eventBlock.transaction_hash, 'event_name':event_name, 'block_records_idx':eventBlock.block_records_idx, 'block_level' : eventBlock.block_level , 'outputsCnt': 1});
						});
					}
				});
			}else if(sendType == 'product'){
				console.log(['sendType', sendType]);
				this.eventInfo.push({'eventHash':eventHash,'blockHash':blockHash, 'definition_id':definition_id, 'transaction_hash':transaction_hash, 'event_name':event_name, 'block_records_idx' : block_records_idx, 'block_level' : null, 'outputsCnt': 1  });
				this.tranType = 'NFTP'
			}
			console.log(['this.eventInfo', this.eventInfo]);
			console.log(['this.tranType', this.tranType]);
			this.showModal = true;
		},
		sendQrModal(){

		},
		transResultFunc(data){
			console.log(['data', data]);
			this.transResultParam = data;
		},
		qrModalClose(){
			this.showModal=false
			if(this.transResultParam == 'success'){
				this.getWallet();
			}
		},
		SendQrModalClose(){
			this.showSendQrModal=false
			if(this.transResultParam == 'success'){
				this.getWallet();
			}
		},
		showSendQrModalFunc(){
			console.log(['this.token', this.token]);
			console.log(['this.token_list', this.token.token_list]);
			console.log(['this.totalToken', this.token.totalToken]);
			this.tokenList = this.token.token_list;
			this.amount = this.token.totalToken;
			this.tranType="NFT";
			this.showSendQrModal = true;
		},
		sendAlert(){
			alert("모든 조각을 찾아야 전송이 가능합니다.");
		},
		inputModal(purchases_idx, delivery_type){
			console.log(["inputModal", purchases_idx, delivery_type ])
			this.purchasesIdx = purchases_idx;
			this.deliveryType = delivery_type;

			this.showInputModal = true;
		},
		InputModalClose(){
			this.showInputModal = false
			if(this.transResultParam == 'success'){
				this.getWallet();
			}
		},
		stateModal(purchases){
			console.log(["stateModal", purchases ])
			this.purchase = purchases;

			this.showStateModal = true;
		},
		stateModalClose(){
			this.showStateModal = false
		},
		blockInfo(transactionHash, created_at){
			console.log('blockinfo')
			this.tranHash = transactionHash;
			this.created_at = created_at;
			this.showBlockChainInfoModal = true;
		},
		showBlockChainInfoModalClose(){
			this.showBlockChainInfoModal = false
		}

		
	}
}
</script>