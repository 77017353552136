<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-header fs-14" style="display: flex; flex-direction: column; justify-content: flex-start;">
                        <div style="align-items: center; text-align: center; font-size:18px !important; font-weight: 400; color: #383838; ">
                        나의 지갑 주소
                        </div>
                        <div style="color: #b7b7b7 !important; font-size: 1em; font-weight: 400; text-align: center; ">QR 코드를 스캔하거나 주소 복사하세요.</div>
                    </div>
                    <div class="modal-body recieve-address">
                        <slot name="body ">
                            <div class="mt-17 recieve-address-qr">
                                <div class="recieve-address-qr-zone">
                                    <div style="padding: 15px;" >
                                        <qrcode-vue :value="address" size="140" ></qrcode-vue>
                                    </div>
                                </div>
                                <div style="margin: 15px;">
                                    {{ address }}
                                </div>
                            </div>
                            <div class="recieve-address-copy text-center mt-24" @click="doCopy">
                                지갑 주소 복사
                            </div>
                        </slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer">
                            <button class="btn modal-default-button" @click="$emit('close')">
                                닫기
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>


<script>
    import localKeystore from '@/local/localKeystore.js'
    import QrcodeVue from 'qrcode.vue'
    
    export default {
        data(){
            return {
                address: '',
            }
        },
        async mounted() {
            let tempAddress = await localKeystore.getAddress();
            this.address = tempAddress;
            console.log(['tempAddress', tempAddress]);
            console.log(['this.address', this.address]);
        },
        components: {
            QrcodeVue
        },
        methods:{
            doCopy(){
                this.$copyText(this.address).then(function (e) {
                    alert('복사되었습니다.')
                }, function (e) {
                    alert('복사가 되지 않았습니다.')
                    console.log(e)
                })
            }
        }
    }
</script>
  
<style lang="scss">
    .recieve-address{
  
      .recieve-address-qr{
        margin: 17px 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
        .recieve-address-qr-zone{
          border: 1px solid #1963ff;
        }
      }
  
      .recieve-address-copy{
        height: 52px;
        background-color: #1963ff;
        border-radius: 4px;
        color:#fff;
        font-size: 14px;
        align-items: center; 
        justify-content: center;
        -webkit-box-pack: center;
        display: flex;
      }
    }
</style>