<template> 
  <div class="container" :id="name">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'ContainerVue',
    props: ['name']
  }
</script>

<style lang="scss" >
  // .container{
  //   width: 100vw;
  //   height: 80vh;
  //   -webkit-box-orient: vertical;
  //   -webkit-box-direction: normal;
  //   -ms-flex-direction: column;
  //   flex-direction: column;
  // }
</style>