import { ec as EC } from 'elliptic';
import {  map, splitEvery} from 'rambda';
import { keccak_256 } from 'js-sha3';

export const ec = new EC('secp256k1');

const byteToHex = [];

for (let n = 0; n <= 0xff; ++n) {
  const hexOctet = n.toString(16).padStart(2, "0");
  byteToHex.push(hexOctet);
}

export function toHexString(byteArray) {
  const hexOctets = new Array(byteArray.length);

  for (let i = 0; i < byteArray.length; ++i) hexOctets[i] = byteToHex[byteArray[i]];

  return hexOctets.join("");
}

export const buffToHex = (arrayBuffer) => toHexString(new Uint8Array(arrayBuffer));

export const hexStringToArray = (hexString) => {
  return map((hex) => parseInt(hex, 16))(
    splitEvery(2)(hexString)
  );
};

export const toByteArray = (hexString) => Uint8Array.from(hexStringToArray(hexString));

export const encodeNumber = (number) => {
  if (number < 0x80) return [number];
  else {
    var numberHex = number.toString(16);
    if (numberHex.length % 2 == 1) numberHex = '0' + numberHex
    const numberArray = hexStringToArray(numberHex);
    const size = numberArray.length;
    const sizeArray = hexStringToArray((size + 0x80).toString(16));
    if (size < (0xf8 - 0x80) + 1) {
      return sizeArray.concat(numberArray);
    } else {
      return [sizeArray.length + 0xf8 - 1].concat(sizeArray).concat(numberArray);
    }
  }
};

export const encodeTxOutput = (output) => output[0] + toHexString(encodeNumber(output[1]));

export const encodeTx = (tx) => {

  const inputHex = toHexString(encodeNumber(tx.inputs.length)) + tx.inputs.sort().join('');
  const outputHex = toHexString(encodeNumber(tx.outputs.length)) + tx.outputs.map(encodeTxOutput).sort().join('')

  return toHexString(encodeNumber(tx.networkId)) + inputHex + outputHex;
};

export function keccak256(uint8array) {
  return keccak_256(uint8array);
}
