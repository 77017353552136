<template>
  <div>
    <b-modal ref="goods-modal" :title="goodsName" title-class="" ok-only ok-title="닫기">
      <div v-for="(item,index) in imgList" :key="index">
        <img :src="item">
      </div>
      <!-- <img :src="imageSrc + event.detail_image"> -->
      <!--      <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close Me</b-button>-->
      <!--      <b-button class="mt-2" variant="outline-warning" block @click="toggleModal">Toggle Me</b-button>-->
    </b-modal>
  </div>

</template>

<script>
export default {
  name: "GoodsModal",
  props: ['openModal', 'imgList', 'goodsName'],
  data() {
    return {
      user: {},
    }
  },
  components: {},
  created() {
    console.log(["goodsModal created",this.goods]);
    
  },
  mounted() {
    console.log("goodsModal mounted");
    this.user = localStorage.getItem('user-info');
    
  },
  methods: {

  },
  updated() {
    console.log("goodsModal updated");
    // for(var i = 1; i <= this.goods.detail_count ;i++ ){
    //   console.log(i);
    //   this.imgList.push(this.imageSrc+this.goods.image_path+"detail"+i+this.goods.detail_ext);
    // }
    // console.log(["goodsModal",this.imgList]);
  }
}
</script>

<style scoped>

</style>
