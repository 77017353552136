<template>
  <div></div>
</template>

<script>
import { postData , showToast } from '@/api/serverApi';

const deviceKey = 'device-info';
const userKey = 'user-info';

export default {
  name: "FindQr",
  data() {
    return {
      user_code: ''
    }
  },
  async created() {
    
  },
  async mounted() {
    this.$log.debug(['mounted FindQr', this.$route.query]);

    if (this.$route.query.a) {
      this.$log.debug(['쿼리 있음'])

      // 읽은 파일 기준으로 중복 여부 확인
      // let definitionId = this.$route.query.a;
      let eventHash = this.$route.query.a;
      let blockHash = this.$route.query.b;
      let blockIndex = this.$route.query.c;
      
      postData('/user/eventInfo',{event_hash : eventHash}).then( async res => {
        this.$log.debug(['postEventInfo', res]);
        if (res.data.success) {
          const eventInfo = res.data.event_info;

          const toDay = Date.now();
          const startDate = new Date(eventInfo.start_date + ' 00:00:00').getTime();
          const endDate = new Date(eventInfo.end_date + ' 23:59:59').getTime();

          const settingsObject = {singular: false, position: 'center-center', theme: 'light', iconEnabled: false, hideProgressbar: true, withBackdrop: true}
          this.$vToastify.setSettings(settingsObject);

          if (toDay > endDate) {
            // 종료 이벤트 안내
            this.$vToastify.prompt({
              title: '이벤트 종료안내',
              body: '이벤트 기간이 종료되었습니다.<br>다음 이벤트에 참여해주세요.',
              answers: { "확인": true }
            }).then( value => {
              if (value) {
                this.goMain();
              }
            })
          } else if (toDay < startDate) {
            // 시작전 이벤트
            this.$vToastify.prompt({
              title: '이벤트 안내',
              body: '이벤트 기간이 아닙니다.<br>이벤트 기간을 확인해주세요.',
              answers: { "확인": true }
            }).then( value => {
              if (value) {
                this.goMain();
              }
            })
          } else {
            // 찾기
            // 사용자 정보를 가져온다.
            const user = localStorage.getItem(userKey);
            this.$log.debug(['user', user]);
            let userInfo = null;
            if (user) {
              userInfo = JSON.parse(user);
              this.user_code = userInfo.user_code;
            }
            const privKey = localStorage.getItem(deviceKey);
            console.log(['privKey', privKey])
            // 블럭 수집 정보를 서버로 보낸다.
            let data = {
              // definition_id: definitionId,
              event_hash: eventHash,
              block_hash : blockHash,
              block_index : blockIndex.toString(),
              user_code : userInfo.user_code
            }

            await postData('/user/findBlock', data).then( res => {
              this.$log.debug(['findBlock', res]);
              showToast('블럭을 찾았습니다.', 'success');
              this.$router.push('/');
            }).catch(e => {
              this.$log.error(['조각 수집 error', e]);
            })
          }
        } else {
          this.$vToastify.prompt({
            title: '이벤트 안내',
            body: '이벤트 정보를 챶을 수 없습니댜.',
            answers: { "확인": true }
          }).then( value => {
            if (value) {
              this.goMain();
            }
          })
        }
      }).catch( err => {
        this.$log.error(['시스템 오류 error', err]);
        this.$vToastify.prompt({
          title: '이벤트 안내',
          body: '시스템 오류가 발생했습니다.',
          answers: { "확인": true }
        }).then( value => {
          if (value) {
            this.goMain();
          }
        })
      });


    } else {
      this.$log.debug(['쿼리 없음'])
      this.$vToastify.prompt({
        title: '알림',
        body: '비정상 접근입니다.',
        answers: { "확인": true }
      }).then( value => {
        if (value) {
          this.goMain();
        }
      })
    }


  },
  methods: {
    goMain() {
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>

</style>
