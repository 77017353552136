<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header fs-14" v-if="error">
            <slot name="header">
              {{ error }}
            </slot>
          </div>
          <div class="modal-body" v-else>
            <div v-if="'DELIVERY_TYPE02' == deliveryType">
              <div style="align-items: center; text-align: center; font-size:18px !important; font-weight: 400; color: #383838; ">
                쿠폰 변경 요청
              </div>
              <div style="font-size:16px; color: #383838; font-weight: 700;">
                쿠폰받을 전화번호
              </div>
              <input type="text" placeholder="전화번호 입력" style="border-bottom: 1px solid #e3e3e3; height: 30px; width: 100%;  margin-bottom: 10px; border: 1px solid #adb5bd;" v-model="phone"/>
              <div @click="sendDelivery()" style="font-size: 16px !important; display : flex; justify-content: center; align-items : center; height: 30px; background-color: #1963ff; color: #fff;">전송</div>
            </div>
            <div v-if="'DELIVERY_TYPE03' == deliveryType">
              <div style="align-items: center; text-align: center; font-size:18px !important; font-weight: 400; color: #383838; ">
                배송 요청
              </div>
              <div style="font-size:16px; color: #383838; font-weight: 700;">
                수령인
              </div>
              <input type="text" placeholder="수령인 입력" style="border-bottom: 1px solid #e3e3e3; height: 30px; width: 100%;  margin-bottom: 10px; border: 1px solid #adb5bd;" v-model="receiver" />
              <div style="font-size:16px; color: #383838; font-weight: 700;">
                연락처
              </div>
              <input type="text" placeholder="연락처 입력" style="border-bottom: 1px solid #e3e3e3; height: 30px; width: 100%;  margin-bottom: 10px; border: 1px solid #adb5bd;" v-model="phone"/>
              <div style="font-size:16px; color: #383838; font-weight: 700;">
                우편번호
              </div>
              <input id="zipcode" type="text" placeholder="우편번호 입력" style="border-bottom: 1px solid #e3e3e3; height: 30px; width: 100%;  margin-bottom: 10px; border: 1px solid #adb5bd; background-color: #e9ecef;" readonly  v-model="zipcode" @click="search()"/>
              <button @click="search()" class="text-center mt-24"> 주소 찾기</button>
              <div style="font-size:16px; color: #383838; font-weight: 700;" >
                주소
              </div>
              <input id="address" type="text" placeholder="주소 입력" style="border-bottom: 1px solid #e3e3e3; height: 30px; width: 100%;  margin-bottom: 10px; border: 1px solid #adb5bd; background-color: #e9ecef;" readonly  v-model="address" @click="search()"/>
              <div style="font-size:16px; color: #383838; font-weight: 700;">
                상세 주소
              </div>
              <input type="text" placeholder="상세 주소 입력" style="border-bottom: 1px solid #e3e3e3; height: 30px; width: 100%;  margin-bottom: 10px; border: 1px solid #adb5bd;" v-model="address_etc"/>
              <div style="font-size:16px; color: #383838; font-weight: 700;">
                배송메세지
              </div>
              <input type="text" placeholder="배송메세지 입력" style="border-bottom: 1px solid #e3e3e3; height: 30px; width: 100%;  margin-bottom: 10px; border: 1px solid #adb5bd;" v-model="delivery_message"/>
              <div @click="sendDelivery()" style="font-size: 16px !important; display : flex; justify-content: center; align-items : center; height: 30px; background-color: #1963ff; color: #fff;">전송</div>
            </div>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button class="btn modal-default-button" @click="$emit('close')">
                닫기
              </button>
            </slot>
          </div>
        </div>
      </div>
      
    </div>
  </transition>
</template>
  
<script>
import {postData} from '@/api/serverApi';

export default {
  name: "InputModal",
  props: {
    initPurchasesIdx: Number,
    initDeliveryType: String
  },
  data() {
    return {
      user: {},
      purchaseIdx : this.initPurchasesIdx,
      deliveryType : this.initDeliveryType,
      receiver : '',
      phone : '',
      zipcode : '',
      address : '',
      address_etc : '',
      delivery_message : '',
    }
  },
  components: {},
  created() {
    console.log(["InputModal created"]);
    
  },
  mounted() {
    console.log("InputModal mounted");
    this.user = localStorage.getItem('user-info');
    
  },
  methods: {
    search(){ //@click을 사용할 때 함수는 이렇게 작성해야 한다.
      new window.daum.Postcode({
        oncomplete: (data) => { //function이 아니라 => 로 바꿔야한다.
            // 팝업에서 검색결과 항목을 클릭했을때 실행할 코드를 작성하는 부분.

            // 도로명 주소의 노출 규칙에 따라 주소를 표시한다.
            // 내려오는 변수가 값이 없는 경우엔 공백('')값을 가지므로, 이를 참고하여 분기 한다.
            var roadAddr = data.roadAddress; // 도로명 주소 변수
            var extraRoadAddr = ''; // 참고 항목 변수

            // 법정동명이 있을 경우 추가한다. (법정리는 제외)
            // 법정동의 경우 마지막 문자가 "동/로/가"로 끝난다.
            if(data.bname !== '' && /[동|로|가]$/g.test(data.bname)){
                extraRoadAddr += data.bname;
            }
            // 건물명이 있고, 공동주택일 경우 추가한다.
            if(data.buildingName !== '' && data.apartment === 'Y'){
                extraRoadAddr += (extraRoadAddr !== '' ? ', ' + data.buildingName : data.buildingName);
            }
            // 표시할 참고항목이 있을 경우, 괄호까지 추가한 최종 문자열을 만든다.
            if(extraRoadAddr !== ''){
                extraRoadAddr = ' (' + extraRoadAddr + ')';
            }

            // 우편번호와 주소 정보를 해당 필드에 넣는다.
            this.zipcode = data.zonecode;
            this.address = roadAddr;
            this.address = data.jibunAddress;
            
            // // 참고항목 문자열이 있을 경우 해당 필드에 넣는다.
            // if(roadAddr !== ''){
            //     document.getElementById("extraAddress").value = extraRoadAddr;
            // } else {
            //     document.getElementById("extraAddress").value = '';
            // }

            // var guideTextBox = document.getElementById("guide");
            // // 사용자가 '선택 안함'을 클릭한 경우, 예상 주소라는 표시를 해준다.
            // if(data.autoRoadAddress) {
            //     var expRoadAddr = data.autoRoadAddress + extraRoadAddr;
            //     guideTextBox.innerHTML = '(예상 도로명 주소 : ' + expRoadAddr + ')';
            //     guideTextBox.style.display = 'block';

            // } else if(data.autoJibunAddress) {
            //     var expJibunAddr = data.autoJibunAddress;
            //     guideTextBox.innerHTML = '(예상 지번 주소 : ' + expJibunAddr + ')';
            //     guideTextBox.style.display = 'block';
            // } else {
            //     guideTextBox.innerHTML = '';
            //     guideTextBox.style.display = 'none';
            // }
        }
      }).open();
    },
    sendDelivery(){
      if("DELIVERY_TYPE02" == this.deliveryType){
        if("" == this.phone){
          alert("쿠폰받을 전화번호를 입력해 주세요.");
          return;
        }
        if (confirm("쿠폰받을 번호 : "+ this.phone + " 로 전송 받으시겠습니까?")) {
          const data = {
            receiver : '',
            phone : this.phone,
            zipcode : '',
            address : '',
            address_etc : '',
            delivery_message : '',
            deliveryType : this.deliveryType,
            purchaseIdx : this.purchaseIdx,
          };

          postData('/user/deliveryStateUpdate', data).then( res => {
            console.log(['deliveryStateUpdate', res, res.data.success]);
            console.log(['deliveryStateUpdate', res, res.data]);
            if (res.data.success) {
              alert("전송되었습니다.");
              this.$emit("transResult", 'success');
              this.$emit('close')
            }else{
              alert(res.data.errorMassage);
              this.$emit("transResult", 'fail');
            }
          })
        }
      }else if("DELIVERY_TYPE03" == this.deliveryType){
        if("" == this.receiver){
          alert("수령인을 입력해 주세요.");
          return;
        }
        if("" == this.phone){
          alert("연락처를 입력해 주세요.");
          return;
        }
        if("" == this.zipcode){
          alert("우편번호를 입력해 주세요.");
          return;
        }
        if("" == this.address){
          alert("주소를 입력해 주세요.");
          return;
        }
        if("" == this.address_etc){
          alert("상세주소를 입력해 주세요.");
          return;
        }
        if (confirm("배송요청 \n\n수령인 : "+ this.receiver + "\n연락처 : "+ this.phone + "\n우편번호 : "+ this.zipcode + "\n주소 : "+ this.address + "\n상세주소 : "+ this.address_etc + "\n배송메세지 : "+ this.delivery_message + "\n\n위의 정보로 요청하시겠습니까?")) {
          const data = {
            receiver : this.receiver,
            phone : this.phone,
            zipcode : this.zipcode,
            address : this.address,
            address_etc : this.address_etc,
            delivery_message : this.delivery_message,
            deliveryType : this.deliveryType,
            purchaseIdx : this.purchaseIdx,
          };

          postData('/user/deliveryStateUpdate', data).then( res => {
            console.log(['deliveryStateUpdate', res, res.data.success]);
            console.log(['deliveryStateUpdate', res, res.data]);
            if (res.data.success) {
              alert("전송되었습니다.");
              this.$emit("transResult", 'success');
              this.$emit('close')
            }else{
              alert(res.data.errorMassage);
              this.$emit("transResult", 'fail');
            }
          })
        }
      }

    }

  },
  updated() {
    console.log("InputModal updated");
    // for(var i = 1; i <= this.goods.detail_count ;i++ ){
    //   console.log(i);
    //   this.imgList.push(this.imageSrc+this.goods.image_path+"detail"+i+this.goods.detail_ext);
    // }
    // console.log(["goodsModal",this.imgList]);
  }
}
</script>

<style scoped>

</style>
  