// import nodeApi from "@/components/api/nodeApi";
import { postData, showToast } from '@/api/serverApi';

const state = {
  authenticated: false,
  uuid : '',
  device_key: 'DEVICE-ID'
}
// getters
const getters = {

}
// mutations
const mutations = {
}
// actions
const actions = {
  findBlock: (context, payload) => {
    console.log(['action findBlock', payload]);
    const block = payload.blockItem;
    const event = payload.event;
    // 사용자 정보를 가져온다.
    const user = localStorage.getItem('user-info');
    let userInfo = null;
    console.log(['findBlock user', user]);
    if (user) {

      userInfo = JSON.parse(user);

      console.log(['findBlock userInfo', userInfo, userInfo.user_code]);

      // this.goods.user_code = userInfo.user_code;
    }
    // 조각 바로 모음 -
    const data = {
      user_code : userInfo.user_code,
      definition_id : event.definition_id,
      event_code : event.event_code,
      event_hash : event.event_hash,
      block_hash : block.block_hash,
      qr_code : block.qr_code,
      block_type : block.block_type,
      team_check : false,
      score: 0,
    };

    postData('/user/findBlock', data).then( res => {
      console.debug(['findBlock', res]);
      if (res && res.data.success) {
        showToast('블럭을 찾았습니다.', 'success');
        // this.getEvent();
      } else {
        showToast(res.data.msg);
      }
    }).catch( err => {
      console.error(err)
      showToast('블럭 찾기 오류입니다.<br>잠시 후 다시 시도해주세요.')
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
