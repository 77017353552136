<template>
  <container-vue name="join-app"  @click.native="hide()">
    
    <main :class="isPinMatched">
      <col-vue cols="12">
        <row-vue mt="20" ml="24">
          <col-vue cols="12">
          <span class="fs-32 text-primary" style="font-size: 1.8rem;">전자지갑을 생성하시겠어요?</span>
          </col-vue>
          <col-vue cols="12" class="mt-12 text-gray" style="font-size: 1rem;">
            <span>
              전자지갑에 로그인할때 사용할<br>
              핀번호를 설정해주세요.
            </span>
          </col-vue>
        </row-vue>
        <row-vue class="mx-12 mt-32 mb-32 error-alert">
          <col-vue cols="12" class="err-box text-left">
            <icon-vue name="warning" class="ml-16"></icon-vue>
            <span class="ml-8">
              비밀번호가 일치하지 않습니다.
            </span>
          </col-vue>
        </row-vue>
        <row-vue mt="40">
          <col-vue cols="12">
            <span :class="`ml-32 fs-12 ${pinTarget === 'enter'? 'text-primary': ''}`">PIN 번호 6자리를 설정해주세요.</span>
          </col-vue>
        </row-vue>
        <row-vue class="mx-15">
          <col-vue cols="12" :class="`pin-number ${pinTarget === 'enter'? 'active': ''}`" @click.native.stop="show('enter')" v-scroll-to="'.pin-number-keyboard'">
            <div class="text-center" v-for="(v, idx) in 6" :key="v" >
                <span v-html="pinNumber['enter'][idx] && pinNumber['enter'][idx].text != null? pinNumber['enter'][idx].text: '&nbsp;'" class="fs-20"></span>
            </div>
          </col-vue>
        </row-vue>
        <row-vue mt="32">
          <col-vue cols="12">
            <span :class="`ml-32 fs-12 ${pinTarget === 'confirm'? 'text-primary': ''}`">PIN 번호를 확인해주세요.</span>   
          </col-vue>
        </row-vue>
        <row-vue class="mx-15">
          <col-vue cols="12" :class="`pin-number ${pinTarget === 'confirm'? 'active': ''}`" @click.native.stop="show('confirm')"  v-scroll-to="'.pin-number-keyboard'">
            <div class="text-center" v-for="(v, idx) in 6" :key="v">
                <span v-html="pinNumber['confirm'][idx] && pinNumber['confirm'][idx].text != null? pinNumber['confirm'][idx].text: '&nbsp;'" class="fs-20"></span>
            </div>
          </col-vue>
        </row-vue> 
      </col-vue>
    </main>
    <footers>
      <div class="pin-number-keyboard" v-if="isPinCodeState === true && pinTarget" @click.stop="show()"  > 
        <div class="text-center" v-for="v in numberKeys" :key="v" @click="setPinNumber(v)" style="display : flex; justify-content: center; align-items : center;">
          <div v-if="v === 'backspace'" class="icon backspace-white" style="background-color: rgb(25, 99, 255); width:40px; height: 21px;  text-align: center !important;  "></div>
          <span v-else>{{ v }}</span>
        </div>
      </div>
      <row-vue mb="40" mt="20" class="mx-32" v-else>
        <col-vue cols="12">
          <div :class="`signup text-center ${isPinMatched === 'match'? 'active': ''}`"  @click="signUp()" style="font-size: 16px !important; display : flex; justify-content: center; align-items : center;">전자지갑 생성하기</div>
        </col-vue>
      </row-vue>
    </footers>
  </container-vue>
</template>

<script>
  import {postData} from '@/api/serverApi';
  import localKeystore from '../local/localKeystore.js'
  import { buffToHex} from '@/crypto/cryptoutil.js'

  export default {
    name: 'joinView',
  
    data(){
      return {
        isPinCodeState: false,
        pinTarget: '',
        numberKeys: null,
        pinCode: [],
        isValid: false,
        pinNumber: {
          enter: [],
          confirm: []
        },
      }
    },
    created(){
      let arrKey = []
      for (let i=1; i <= 9; i++){
        arrKey.push(i)
      }
      this.numberKeys = arrKey.concat([null, 0, 'backspace'])
    },
    computed: {
      isPinMatched(){
        let pinEnter = this.pinNumber['enter']
        let pinConfirm = this.pinNumber['confirm']
        if ( pinEnter.length === 6 &&  pinConfirm.length === 6){
          if ( pinEnter.map((v) => v.value ).join('') === pinConfirm.map((v) => v.value ).join('') ){
            return 'match'
          }else{
            return 'error'
          }
        }else{
          return 'ready'
        }
      }
    },
    methods: {
      show(pinTarget){
        if (pinTarget)
          this.pinTarget = pinTarget
        
        this.isPinCodeState = true
      },
      hide(){
        this.isPinCodeState = false
        this.pinTarget = null
      },
      setPinNumber(val){
        if (val === 'backspace'){
          this.pinNumber[this.pinTarget].pop()
        }else if(this.pinNumber[this.pinTarget].length < 6 && val !== null){
          this.pinNumber[this.pinTarget].forEach((v, k) => {
            const pinVal = this.pinNumber[this.pinTarget][k].value
            this.pinNumber[this.pinTarget][k] = {
              text: '*',
              value: pinVal 
            }
          })
          this.pinNumber[this.pinTarget].push({
            value: Number(val),
            text: Number(val),
          })
        }
        

        if (this.pinNumber[this.pinTarget].length >= 6){
          this.hide()
        }
      },
      signUp() {
        console.log("signUp " + this.isPinMatched);
        console.log("pinNumber1 " + this.pinNumber['enter'].map((v)=> v.value).join(''));
        if (this.isPinMatched === 'match') {
          //키 생성
          localKeystore.initialize(this.pinNumber['enter'].map((v)=> v.value).join('')).then( address => {
            console.log(['address', address]);
            if(address){
              //지갑 주소 저장
              let user = localStorage.getItem('user-info');
              let userInfo = JSON.parse(user);
              console.log("signUp user : " + user);
              console.log("signUp user.user_code : " + userInfo.user_code);
              console.log("signUp address : " + address);
              const data = {
                user_code : userInfo.user_code,
                address : address
              };

              postData('/user/postUserWalletUpdate', data).then( res => {
                this.$log.debug(['postUserWalletUpdate', res]);
                this.$router.push('/login');
              }).catch( err => {
                this.$log.error(err);
                alert("에러가 발생했습니다. 잠시 후 다시 시도해주십시요.");
              })

              //bbgo createname 주소 등록
              // localKeystore.getPrivateKey(this.pinNumber['enter'].map((v)=> v.value).join('')).then( retunrpk => {
              //   console.log(['getPrivateKey', buffToHex(retunrpk)]);

                // const data1 = {
                //   privKey : buffToHex(retunrpk),
                //   address : address,
                //   user_code : userInfo.user_code,
                // };
                // console.log(["signUp data1", data1]);

                // postData('/user/postTransactionCreateName', data1).then( res => {
                //   this.$log.debug(['postTransactionCreateName', res]);
                // }).catch( err => {
                //   this.$log.error(err);
                // })

                
                
              // }).catch( err => {
              //   console.log(err);
              // })

              
            }
          })
          
        }
      },
    },
    watch: {
      pinTarget(a, b){
        (this.pinNumber[b]||[]).forEach((v, k) => {
          const pinVal = this.pinNumber[b][k].value
          this.pinNumber[b][k] = {
            text: '*',
            value: pinVal 
          }
        })
      }
    }
  }
</script>

<style lang="scss" >

  #join-app{
    
    .container{
      display: flex;
      width: 100vw;
      height: 100vh;
      flex-direction: column;
    }

    padding-top: 54px !important;

    header{
      position: fixed;
      top: 0;
      width: 100vw;
      box-sizing: border-box;
      background-color: #fff;
    }

    
    main{
      
      .error-alert{
        display: none;
      }

      &.error{
        .row:not(:first-child) span{
          color: #f74c26 !important; 
        }
        .error-alert{
          display: flex;
        }
      }


      .err-box{
        align-items: center;
        border-radius: 4px;
        border: solid 1px #e3e3e3;
        background-color: #fafcfd;
        height: 40px;
        object-fit: contain;
        color: #f74c26;
      }

      .pin-number {
        div {
          span{
            font-size: 40px !important;
          }
          width: 16.6%;
          float: left;
          height: 64px;
          position: relative;

          &:after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 10%;
            width: 80%;
            border-bottom: 1px solid #b7b7b7 !important;
          }
        }

        &.active{
          div {
            &:after{
              border-bottom: 1px solid #1963ff;
            }
          }
        }
      }
    }
    
    footers{
      margin: 50px;
      .signup {
        min-height: 52px;
        color: #fff;
        background-color: #e3e3e3;

        &.active{
          background-color: #1963ff; 
        }
      }
      .pin-number-keyboard {
        span{
          font-size: 20px !important;
          line-height: 41px;
          color: #fff;
        }

        div{
          width: 33%;
          float: left;
          height: 52px;
          border: solid 0.1px rgba(168, 195, 255, 0.5);
          background-color: #1963ff;
          font-size: 20px;
          color: #fff;
        }
      }
        
      .create-wallet {
        height: 64px;
        color: #1963ff;
        font-size: 18px;
        border: solid 1px #1963ff;
      }
    }

    
  }
</style>
