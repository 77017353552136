<template>
  <div>
    <div class="img-wrapper">
      <div class="front">
        <router-link :to="{ path: '/goods/'+product.goods_code}">
          <img
            :src='imageSrc+product.goods_thumbnail'
            :id="product.id"
            class="img-fluid bg-img"
            :alt="product.title"
            :key="index"
          />
        </router-link>
      </div>
    </div>
    <div class="product-detail">
      <router-link :to="{ path: '/goods/'+product.goods_code}">
        <h6>{{ product.goods_name }}</h6>
      </router-link>
      <p>{{ product.start_date }}~{{ product.end_date }}</p>
      <h4>{{ product.goods_price | makeComma }}원 </h4>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: ['product', 'index'],
  data() {
    return {
      imageSrc: '',
      quickviewProduct: {},
      compareProduct: {},
      cartProduct: {},
      showquickview: false,
      showCompareModal: false,
      cartval: false,
      dismissSecs: 5,
      dismissCountDown: 0
    }
  },
  mounted() {
    // let IMG_URL = 'https://api.wonhago.com';
    let IMG_URL = process.env.VUE_APP_API_URL;

    if (process.env.NODE_ENV === 'development') {
      IMG_URL = 'http://localhost:3004';
    }

    this.imageSrc = IMG_URL;
  },
  computed: {
    ...mapState({
      productslist: state => state.products.productslist
    }),
    ...mapGetters({
      curr: 'products/changeCurrency'
    })
  },
  created() {
    // let IMG_URL = 'https://api.wonhago.com';
    let IMG_URL = process.env.VUE_APP_API_URL;

    if (process.env.NODE_ENV === 'development') {
      IMG_URL = 'http://localhost:3004';
    }

    this.imageSrc = IMG_URL;

    console.log(['product-box product', this.product]);
  },
  methods: {
    addToCart: function (product) {
      this.cartval = true
      this.cartProduct = product
      this.$emit('opencartmodel', this.cartval, this.cartProduct)
      this.$store.dispatch('cart/addToCart', product)
    },
    addToWishlist: function (product) {
      this.dismissCountDown = this.dismissSecs
      this.$emit('showalert', this.dismissCountDown)
      this.$store.dispatch('products/addToWishlist', product)
    },
    showQuickview: function (productData) {
      this.showquickview = true
      this.quickviewProduct = productData
      this.$emit('openquickview', this.showquickview, this.quickviewProduct)
    },
    addToCompare: function (product) {
      this.showCompareModal = true
      this.compareProduct = product
      this.$emit('showCompareModal', this.showCompareModal, this.compareProduct)
      this.$store.dispatch('products/addToCompare', product)
    },
    
    productColorchange(color, product) {
      product.variants.map((item) => {
        if (item.color === color) {
          product.images.map((img) => {
            if (img.image_id === item.image_id) {
              this.imageSrc = img.src
            }
          })
        }
      })
    },
    productVariantChange(imgsrc) {
      console.log("I am calll");
      this.imageSrc = imgsrc
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown
      this.$emit('alertseconds', this.dismissCountDown)
    },
    discountedPrice(product) {
      const price = product.price - (product.price * product.discount / 100)
      return price
    }
  }
}
</script>
