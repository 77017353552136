<template>
    <div id="home">
        <div class="inner">
            <div class="top">

            </div>
            <div class="container">
                <div class="row">
                <div class="col">
                    <div class="theme-tab">
                    <div class="no-slider row">
                        <div
                            class="product-box"
                            v-for="(product,index) in goods_list"
                            :key="index"
                        >
                        <productBox1
                            :product="product"
                            :index="index"
                        />
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        <EventModal ref="eventModal" :imageSrc="imageSrc" :event="modalEvent"></EventModal>
    </div>
</template>

<script>
// import Swip  er from 'https://unpkg.com/swiper@8/swiper-bundle.esm.browser.min.js'
// import Swiper from 'swiper';
// import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
// import "swiper/swiper.min.css";
// import 'swiper/swiper.scss'
import { getEthPrice, postData, showToast } from '../api/serverApi';
import productBox1 from './product-box1'
import moment from "moment";
import EventModal from "@/views/EventModal";
export default {
    name: "homeView",
    components: {
        EventModal,
        productBox1
    },
    
    data() {
        return {
            imageSrc: '',
            modalEvent: {},
            mission_list: [],
            upcoming_list: [],
            onday_list: [],
            topreward_list: [],
            ranking_list: [],
            goods_list: [],
            eth_price: 0,
            nowTime: null,
            user: {},
            
            
        }
    },
    mounted() {
        console.log(['mounted', process.env.VUE_APP_API_URL]);
        this.user = localStorage.getItem('user-info');
        this.nowTime = moment(new Date()).format('Y.MM.D HH:mm:ss');
        // let IMG_URL = 'https://api.wonhago.com';
        let IMG_URL = process.env.VUE_APP_API_URL;

        if (process.env.NODE_ENV === 'development') {
          IMG_URL = 'http://localhost:3004';
        }

        this.imageSrc = IMG_URL;

        this.getMain();


        if (window.NodeList && !NodeList.prototype.forEach) {
            NodeList.prototype.forEach = Array.prototype.forEach;
        }

        const sliders = document.querySelectorAll('.scroll_area');
        function preventClick(e) {
            e.preventDefault();
            e.stopImmediatePropagation();
        }
        let isDown = false;
        let isDragged = false;
        let startX;
        let scrollLeft;

        sliders.forEach(function (slider) {
            slider.addEventListener("mousedown", function (e) {
                isDown = true;
                slider.classList.add("active");
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
        });

        sliders.forEach(function (slider) {
            slider.addEventListener("mouseleave", function () {
                isDown = false;
                slider.classList.remove("active");
            });
        });

        sliders.forEach(function (slider) {
            slider.addEventListener("mouseup", function () {
                isDown = false;
                const elements = slider.querySelectorAll("a");
                if (isDragged) {
                    for (let i = 0; i < elements.length; i++) {
                        elements[i].addEventListener("click", preventClick);
                    }
                }
                else {
                    for (let i = 0; i < elements.length; i++) {
                        elements[i].removeEventListener("click", preventClick);
                    }
                }
                slider.classList.remove("active");
                isDragged = false;
            });
        });

        sliders.forEach(function (slider) {
            slider.addEventListener("mousemove", function (e) {
                if (!isDown) return;
                isDragged = true;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 2;
                slider.scrollLeft = scrollLeft - walk;
            });
        });
    },
    methods: {
        getMain() {
            getEthPrice().then(res => {
                // console.log(['res', res]);
                if (res && res.data) {
                    console.log(['getEthPrice', res.data[0], res.data[0].trade_price]);
                    this.eth_price = res.data[0].trade_price;
                }
            });

            // main list
            const param = {
                site_code: 'blockchain',
                event_status: 'EVENT_STATUS01'
            }

            postData('/user/main', param).then(res => {
                console.log(['main', res, res.data.success]);
                if (res.data.success) {

                    this.mission_list = res.data.event_list;
                    this.upcoming_list = res.data.upcoming_list;
                    this.onday_list = res.data.onday_list;
                    this.topreward_list = res.data.topreward_list;
                    this.ranking_list = res.data.ranking_list;
                    this.goods_list = res.data.goods_list;
                }
            })

            console.log(['mission_list', this.mission_list.length]);
        },
        getETHPrice() {
            // api.getETHPrice().then( res => {
            //   // console.log(['res', res]);
            //   if (res && res.data) {
            //     // console.log(['data', res.data[0], res.data[0].trade_price]);
            //     this.eth_price = res.data[0].trade_price;
            //   }
            // });

        },
        goDetail(item) {
            console.log('goDetail', item);
            if (item.detail_image) {
                this.modalEvent = item;
                this.$refs['eventModal'].$refs["event-modal"].show();
            } else {
                showToast('안내페이지가 없습니다.');
            }
        },
        goStart(item) {
            console.log('goStart');
            this.$router.push('/event/' + item.event_code)
        },
        voteInterest(item) {
            console.log(['voteInterest', item]);
            //
            if (this.user) {
                const user = JSON.parse(this.user);

                const param = {
                    event_code: item.event_code,
                    user_code: user.user_code,
                };

                postData('/user/setInterest', param).then(res => {
                    if (res.data.success) {
                        this.getMain();
                    }
                })
            }
        }
    }
}
</script>
