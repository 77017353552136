<template>
  <div></div>
</template>

<script>
import { postData , showToast } from '@/api/serverApi';

const deviceKey = 'device-info';
const userKey = 'user-info';

export default {
  name: "ShareView",
  async mounted() {
    this.$log.debug(['mounted find', this.$route.query]);

    const params = this.$route.query;

    // 읽은 파일 기준으로 중복 여부 확인
    // let definitionId = this.$route.query.a;
    let eventHash = params.a;
    let blockHash = params.b;
    // let blockIndex = this.$route.query.c;
    let userCode = params.c;
    let blockType = params.d;
    let shareUser = params.e;

    // 사용자 정보를 가져온다.
    const storageUser = localStorage.getItem(userKey);
    const storageDevice = localStorage.getItem(deviceKey);

    console.log(['eventHash', eventHash]);
    console.log(['blockHash', blockHash]);
    console.log(['userCode', userCode]);
    console.log(['blockType', blockType]);
    console.log(['shareUser', shareUser]);
    console.log(['storageUser', storageUser]);
    console.log(['storageDevice', storageDevice]);
    // a : 광고해쉬, b : 블록코드, c : 사용자해쉬, d : 공유타입 (BLOCK_TYPE05 : 공유, BLOCK_TYPE06 : 초대)
    if (blockType === 'BLOCK_TYPE06') {
      console.log(['BLOCK_TYPE06']);

      // 사용자 정보를 가져온다.
    //   const user = localStorage.getItem(userKey);

      // 로그인 여부 - 이미 로그인 되있는 사용자는 초대 블록 생성안됨
      // storage.get(common.authKey).then( (res) => {
      //   console.log(['const storage', res]);
      //   if (res) {
      //     console.log(['res join_type', res[0].join_type]);
      //     // const navigationExtras: NavigationExtras = {
      //     //   state: {
      //     //     data: {
      //     //       id: params.a,
      //     //     },
      //     //   },
      //     // }
      //     // this.navCtrl.navigateForward('detail', navigationExtras);
      //     // this.router.navigate(['ct', 'detail'], navigationExtras);
      //   } else {
      //     console.log(['not login set storage']);
      //     // 저장소 저장
      //     const data = {
      //       user_code : params.c,
      //       event_hash : params.a,
      //       block_hash : params.b,
      //     };
      //
      //     // this.storage.set(this.common.inviteKey, data);
      //     this.inviteService.setInvite(data);
      //     // 회원가입 페이지로 이동 시키자
      //     // this.router.navigateByUrl('/public/signupAgree');
      //     this.navCtrl.navigateForward('register-invite');
      //   }
      // });
      // 저장소에 기록한다.
    } else {
      console.log(['BLOCK_TYPE05']);
      // local storage 사용자 정보

      // 초대한 사용자와 보낸 사용자가 같은 경우 블록 수집 안됨.
      let user = null;
      if (storageUser) {
        user = JSON.parse(storageUser);
      }
      console.log(['user check', user.user_code, userCode]);
    //   alert(['user check', user.user_code, userCode]);

      if (user.user_code !== userCode) {
        // 블럭 수집 정보를 서버로 보낸다.
        let data = {
          event_hash: eventHash,
          block_hash : blockHash,
          user_code : userCode
        }

        await postData('/user/findBlock', data).then( res => {
          this.$log.debug(['findBlock', res]);

          showToast('블럭을 찾았습니다.', 'success');
          this.goMain();

        }).catch(e => {
          this.$log.error(['조각 수집 error', e]);
          this.goMain();
          // this.$router.push('/')
        })
      } else {
        showToast('자신에게 공유할 수 없습니다.', 'error', 3000);
        this.goMain();

      }
    }
  }, methods: {
    goMain() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>
