import { render, staticRenderFns } from "./InputModalVue.vue?vue&type=template&id=aa218df2&scoped=true&"
import script from "./InputModalVue.vue?vue&type=script&lang=js&"
export * from "./InputModalVue.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa218df2",
  null
  
)

export default component.exports