<template>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="modal-header fs-14" v-if="error">
              <slot name="header">
                {{ error }}
              </slot>
            </div>
            <div class="modal-body" v-else>
              <div >
                <div style="align-items: center; text-align: center; font-size:18px !important; font-weight: 400; color: #383838; border-bottom: 0.03571rem solid #ced4da!important;">
                  블록 정보
                </div>
                <div style="font-size:16px; color: #383838; font-weight: 700; margin-top: 20px;">
                  Transaction Hash
                </div>
                <div style="font-size:12px; color: #383838; word-break: break-all!important;" >
                  {{ transactionHash }}
                </div>
                <div style="font-size:16px; color: #383838; font-weight: 700; margin-top: 10px;">
                  생성시간
                </div>
                <div style="font-size:12px; color: #383838; word-break: break-all!important;" >
                  {{ created_at }}
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button class="btn modal-default-button" @click="$emit('close')">
                  닫기
                </button>
              </slot>
            </div>
          </div>
        </div>
        
      </div>
    </transition>
  </template>
    
  <script>
  
  export default {
    name: "BlockChainInfoModal",
    props: {
      initTransactionHash: String,
      initCreated_at: String
    },
    data() {
      return {
        transactionHash : this.initTransactionHash,
        created_at : this.initCreated_at,
      }
    },
    components: {},
    created() {
      console.log(["BlockChainInfoModal created"]);
      
    },
    mounted() {
      console.log("BlockChainInfoModal mounted");
      
    },
    methods: {
  
    },
    updated() {
      console.log("BlockChainInfoModal updated");
      // for(var i = 1; i <= this.goods.detail_count ;i++ ){
      //   console.log(i);
      //   this.imgList.push(this.imageSrc+this.goods.image_path+"detail"+i+this.goods.detail_ext);
      // }
      // console.log(["goodsModal",this.imgList]);
    }
  }
  </script>
  
  <style scoped>
  
  </style>
    