<template>
    <header>
        <div class="inner">
            <h1 v-if="mobile==false || (CurrentRoute=='/' || CurrentRoute=='/detail' || custom_filter(CurrentRoute,'/event' ) || CurrentRoute=='/index')">
                <router-link to="/">
                    <img src="../../assets/common/logo.svg" alt="원하고" />
                </router-link>
            </h1>
            <p v-if="mobile==true && (CurrentRoute!=='/' && CurrentRoute!=='/detail'  &&  !custom_filter(CurrentRoute,'/event' ) && CurrentRoute!=='/index' )" id="btn_prev">
                <a href="javascript:" @click="$router.go(-1)">
                    <img src="../../assets/common/ico-22-back.svg" alt="뒤로" />
                    <h1 v-if="CurrentRoute ==='/wallet' || CurrentRoute==='/wallet-list' || CurrentRoute==='/wallet-make' || CurrentRoute==='/wallet-viewitem'">지갑</h1>
                    <h1 v-else-if="CurrentRoute==='/myhome' || CurrentRoute==='/myinfo' || CurrentRoute==='/pin'">안녕하세요</h1>
                    <h1 v-else-if="CurrentRoute==='/join'">지갑생성</h1>
                </a>
            </p>
            <nav :class="{ active: showMenu }">
                <ul>
                    <li>
                        <router-link @click="showMenu=false" :class="{ active: CurrentRoute==='/' }" to="/">홈</router-link>
                    </li>
                    <li>
                        <router-link to="">회사소개</router-link>
                    </li>
                    <li>
                        <router-link to="">서비스</router-link>
                    </li>
                    <li>
                        <router-link to="">WONT SCAN</router-link>
                    </li>
<!--                    <li><a @click="showAlert">로그인</a></li>-->
                </ul>
                <a v-if="mobile" id="btn_close" href="javascript:" @click="showMenu=false">
                    <img src="../../assets/common/ico-24-close.svg" alt="닫기" />
                </a>
            </nav>
            <p v-if="mobile" id="btn_menu">
                <a href="javascript:" :class="{ active: showMenu }" @click="showMenu=true">
                    <img src="../../assets/common/ico-24-nav.svg" alt="메뉴" />
                </a>
            </p>
            <div id="btn_wallet">
<!--                <modal v-if="showModal" v-on:close="showModal = false">-->
<!--                    <span slot="header">New header</span>-->
<!--                </modal>-->
                <transition name="modal" v-if="showModal">
                    <div id="modal_wallet">
                        <Wallet />
                    </div>
<!--                    <div class="modal-mask" v-on:click="modalClose('close')">-->
<!--                        <div class="modal-wrapper">-->
<!--                            -->
<!--                        </div>-->
<!--                    </div>-->


<!--&lt;!&ndash;                            <div class="modal-container">&ndash;&gt;-->
<!--                                <div id="modal_wallet" v-if="showModal">-->
<!--                                    <component :is="Component" />-->
<!--                                </div>-->
<!--&lt;!&ndash;                                <slot name="header">Header</slot>&ndash;&gt;-->
<!--&lt;!&ndash;                                <button class="modal-default-button" v-on:click="modalClose('close')">Close</button>&ndash;&gt;-->
<!--&lt;!&ndash;                            </div>&ndash;&gt;-->


                </transition>
                <router-link to="/wallet" v-if="mobile">
                    <img v-if="mobile==true && (CurrentRoute!=='/' && CurrentRoute!=='/detail' && CurrentRoute!=='/index' && !custom_filter(CurrentRoute,'/product' ) )" src="../../assets/common/ico-24-wallet-active.svg" alt="지갑" />
                    <img v-else src="../../assets/common/ico-24-wallet.svg" alt="지갑" />
                </router-link>
                <!-- <router-link to="/product" v-if="mobile">
                    <img v-if="mobile==true && (CurrentRoute!=='/' && CurrentRoute!=='/detail')" src="../../assets/common/ico-24-wallet-active.svg" alt="상품" />
                    <img v-else src="../../assets/common/ico-24-wallet.svg" alt="상품" />
                </router-link> -->
                <a href="javascript:" :class="{ active: showModal }" @click="showModal = !showModal" v-else>
                    <img v-if="showModal" src="../../assets/common/ico-24-wallet-active.svg" alt="지갑" />
                    <img v-else src="../../assets/common/ico-24-wallet.svg" alt="지갑" />
                    <span>지갑</span>
                </a>
<!--                <router-view v-slot="{ Component }" name="modal">-->
<!--                    <transition name="fade" mode="out-in">-->
<!--                        <div id="modal_wallet" v-if="showModal">-->
<!--                            <component :is="Component" />-->
<!--                        </div>-->
<!--                    </transition>-->
<!--                </router-view>-->
            </div>
        </div>
        <!-- <div>
            <div class="btn_wrap">
				<input class="btn" type="button" value="Event" @click="pageLoad('event')" />
			</div>
            <div class="btn_wrap">
				<input class="btn" type="button" value="희망거래" @click="pageLoad('goods')"/>
			</div>
        </div> -->
        
    </header>
</template>

<script>
import Wallet from "@/views/Wallet";
var mobile=0;
function w_resize(){
    if (window.innerWidth >= 1000) {
        mobile=0;
    } else {
        mobile=1;
    }
} w_resize();

export default {
    name: "HeaderTop",
    components: {
        Wallet
    },
    data() {
        return {
            CurrentRoute: this.$route.path,
            mobile,
            showModal: false,
            showMenu: false,
            
        }
    },
   
    watch: {
        $route: {
            immediate: true,
            handler(to) {
                console.log(['header to', to]);
                this.CurrentRoute=to.path;
            }
        }
    },
    
    mounted() {
        window.addEventListener('resize', this.handleResize);
	},
    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        handleResize() {
            w_resize();
            return this.mobile = mobile;
        },
        modalClose() {
            this.showModal = false;
        },
        showAlert() {
            const settingsObject = {singular: false, position: 'center-center', theme: 'light', iconEnabled: false, hideProgressbar: true, withBackdrop: true}
            this.$vToastify.setSettings(settingsObject);
            this.$vToastify.prompt({
                // title: '이벤트 종료안내',
                body: '이벤트 기간이 종료되었습니다.<br>다음 이벤트에 참여해주세요.',
                answers: { "확인": true }
            }).then( value => {
                if (value) {
                    // this.goMain();
                }
            })
        },
        pageLoad(param){
            if(param == 'event'){
                this.$router.push('/')
            }else if(param == 'goods'){
                this.$router.push('/goodsHome/')
            }
            alert(param);
        },
        custom_filter(a, b) {
            if (a.indexOf(b) !== -1) {
                return true;
            }
            return false;
        }
    }
}
</script>
