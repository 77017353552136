<template>
  <div class="pin-number-keyboard" @click.stop="isAmountkeyboards(true)"> 
    <div :class="`text-center ${v.val === 'Next'? 'bg-primary': '' }`" v-for="(v, idx) in numberKeys" :key="idx" @click="setNumber(v.val)" style="border: solid #dedede;">
      <!-- <icon-vue name="backspace" bgColor="#fff" v-if="v.val === 'backspace'"></icon-vue> -->
      
      <span  v-if="v.val === 'backspace'" style="display: flex; margin-left: 30%; margin-top: 10%;">
      <!-- {{ v.val }} -->
        <img src="../../assets/common/ico-22-back.svg" alt="back" />
      
      </span>
      <span :class="`${v.val === 'Next'? 'fs-14': '' }`" v-else>
      {{ v.val }}
      </span>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AmountKeyboardVue',
    data(){
      return {
        numberKeys: null,
      }
    },
    created() {
      let arrKey = []
      let otehrKey = {
        3: 'backspace',
        6: 'Next',
      }
      for (let i=1; i <= 9; i++){
        console.log('i : '+i);
        arrKey.push({
          key: i,
          val: i
        })

        if (otehrKey[i]){
          console.log('otehrKey i : '+i);
          arrKey.push({
            key: otehrKey[i],
            val: otehrKey[i]
          })
        }

        if (i === 9){
          arrKey.push({
            key: 0,
            val: 0
          })

          
        }
      }
      this.numberKeys = arrKey
      console.log(this.numberKeys);
    },
    methods: {
      setNumber(v){
        // this.$store.commit('SEND_KRW_AMT', v)
        this.$emit("setNumbers", v);
      },
      isAmountkeyboards(v){
        this.$emit("isAmountkeyboards", v);
      }
    }

  }
</script>

<style lang="scss" scoped>
  .pin-number-keyboard {
    margin-top: rem(56);
    // width: 100vw;

    div{
      width: 25%;
      float: left;
      height: 40px;
      border: solid rem(0.5) #383838;
      box-sizing:border-box;
      background-color: #fff;
      font-size: 20px;
      color: #383838;
    }
  }
</style>

