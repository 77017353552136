import { get, set } from 'idb-keyval';
import { buffToHex, encodeTx, keccak256, toByteArray, toHexString } from '@/crypto/cryptoutil.js'
import { decrypt, encrypt, getKey, getKeyMaterial, getMac, verifyMac } from '@/crypto/keyDerivation.js';
import { KeyPair } from '@/crypto/keypair.js'

const KeystoreKey = 'KeystoreKey';

const save = async (ketstore) => await set(KeystoreKey, ketstore)

const load = async () => await get(KeystoreKey)

export default {

  initialize: async (pinNumber) => {
    console.log('initializing keystore with pin number ' + pinNumber);

    const keyMaterial = await getKeyMaterial(pinNumber);
    const salt = window.crypto.getRandomValues(new Uint8Array(16));
    const aesKey = await getKey(keyMaterial, salt);
    const iv = window.crypto.getRandomValues(new Uint8Array(12));
    const mac = await getMac(aesKey, iv);

    const generated = KeyPair.generateAddressAndPrivateKey();
    const address = generated.address;
    const priv = generated.priv;

    const ciphertext = await encrypt(aesKey, iv, new Uint8Array(priv));

    const keystoreContent = {
      address: address,
      salt: salt,
      iv: iv,
      mac: mac,
      ciphertext: ciphertext,
    }
    console.log(keystoreContent);

    const decrypted = await decrypt(aesKey, iv, ciphertext);
    console.log('is ciphertext correct? ' + (toHexString(priv) === buffToHex(decrypted)))

    await save(keystoreContent);

    return address;
  },

  verify: async (pinNumber) => {
    const keyStore = await load();
    if (keyStore) {
      const keyMaterial = await getKeyMaterial(pinNumber);
      const aesKey = await getKey(keyMaterial, keyStore.salt);
      return await verifyMac(aesKey, keyStore.iv, keyStore.mac);
    }
  },

  getAddress: async () => {
    const keyStore = await load();
    if (keyStore) return keyStore.address;
  },

  signTx: async (pinNumber, tx) => {
    console.log('==> pin number: ' + pinNumber);
    const keyStore = await load();
    if (keyStore) {
      console.log('==> keystore loaded');
      console.log(keyStore);
      const keyMaterial = await getKeyMaterial(pinNumber);
      const aesKey = await getKey(keyMaterial, keyStore.salt);
      const privateKey = await decrypt(aesKey, keyStore.iv, keyStore.ciphertext);
//      console.log(buffToHex(privateKey));

      const txHex = encodeTx(tx);
      const txHash = keccak256(toByteArray(txHex));
      const keyPair = KeyPair.fromPrivate(buffToHex(privateKey));
      const sig = keyPair.sign(txHash);
      return {
	signature: sig,
	value: tx,
      };
    }
  },

  getPrivateKey: async (pinNumber) => {
    console.log('==>getPrivateKey  pin number: ' + pinNumber);
    const keyStore = await load();
    if (keyStore) {
      console.log('==> keystore loaded');
      console.log(keyStore);
      const keyMaterial = await getKeyMaterial(pinNumber);
      const aesKey = await getKey(keyMaterial, keyStore.salt);
      const privateKey = await decrypt(aesKey, keyStore.iv, keyStore.ciphertext);
//    
      return privateKey;
    }
  },

}
