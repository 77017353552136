import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import middleware from './middleware/initialize'
import VueToastify from "vue-toastify";
import Toasted from 'vue-toasted';
import VueLogger from 'vuejs-logger';
import { BModal, BFormFile  } from 'bootstrap-vue'
import { TabsPlugin } from 'bootstrap-vue'
import VueSocialSharing from 'vue-social-sharing'
import VueScrollTo from 'vue-scrollto'
import VueQrcodeReader from 'vue-qrcode-reader'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './css/reset.css';
import './css/type.css';
import './css/layout.css';
import './css/page.css';
import './assets/scss/app.scss';
// import '~@/swiper/swiper-bundle.css';

/** 공통 css**/
//import './assets/css/wallet.scss'

/** 공통 컴포넌트 등록**/
import IconVue from './components/common/IconVue'
import ContainerVue from './components/common/ContainerVue'
import RowVue from './components/common/RowVue'
import ColVue from './components/common/ColVue'
Vue.component(IconVue.name, IconVue)
Vue.component(ContainerVue.name, ContainerVue)
Vue.component(RowVue.name, RowVue)
Vue.component(ColVue.name, ColVue)

// clipboard
import VueClipboard from 'vue-clipboard2'

import './registerServiceWorker'
Vue.use(VueClipboard)

Vue.config.productionTip = false;
Vue.component('b-modal', BModal);
Vue.component('b-form-file', BFormFile);
Vue.use(TabsPlugin);
Vue.use(VueToastify);
Vue.use(VueSocialSharing);
Vue.use(VueQrcodeReader);
Vue.use(Toasted, {
  iconPack: 'fontawesome'
});

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

//* VueLogger 설정 *//
console.log("@@@@@ main.js process.env.NODE_ENV : "+process.env.NODE_ENV)
const loggerEnv = process.env.NODE_ENV;
const logLevel = loggerEnv === 'development' ? 'debug' : loggerEnv === 'info' ? 'debug' : loggerEnv === 'production' ? 'error' : 'info';
console.log("@@@@@ main.js logLevel : "+logLevel)

const logOptions = {
  isEnabled: true,
  logLevel : logLevel,
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
}
Vue.use(VueLogger, logOptions);
Vue.filter("makeComma", val =>{
  return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})

window.Kakao.init('fa0e8b0c996f2dbdc0818f1b89718d7f')

new Vue({
  router,
  store,
  middleware,
  render: h => h(App),
}).$mount('#app')
