import { ec, hexStringToArray, keccak256, toByteArray } from '@/crypto/cryptoutil.js'

export class KeyPair {

  #keyPair;

  constructor(keyPair) {
    this.#keyPair = keyPair;
  }

  publicKey() {
    return this.#keyPair.getPublic().x.toString('hex') + this.#keyPair.getPublic().y.toString('hex');
  }

  address() {
    const publicKeyHash = keccak256(toByteArray(this.publicKey()));
    return publicKeyHash.substring(publicKeyHash.length - 40);
  }

  sign(hash) {
    var ecSig = ec.sign(hash, this.#keyPair);
    return {
      v: 27 + ecSig.recoveryParam,
      r: ecSig.r.toString(16),
      s: ecSig.s.toString(16),
    };
  }

  static generate() {
    return new KeyPair(ec.genKeyPair());
  }

  static generateAddressAndPrivateKey() {
    const eckey = ec.genKeyPair();
    return {
      address: new KeyPair(eckey).address(),
      priv: hexStringToArray(eckey.getPrivate('hex'))
    };
  }

  static fromPrivate(privateKey) {
    return new KeyPair(ec.keyFromPrivate(privateKey));
  }
}
